import { Route, Navigate, Routes } from 'react-router-dom';

import Reports from 'pages/Reports';
import SideMenu from 'layout/SideMenu';
import SiteContent from 'layout/SiteContent';

import Auth from 'pages/Auth';
import Users from 'pages/Users';
import Phones from 'pages/Phones';
import Phone from 'pages/Phone';
import Submission from 'pages/Submission';
import PhoneForm from 'pages/PhoneForm';
import Submissions from 'pages/Submissons';
import UserForm from 'pages/UserForm';
import Settings from 'pages/Settings';
import Partners from 'pages/Partners';
import PartnerForm from 'pages/PartnerForm';
import Addresses from 'pages/Addresses';
import HeroImages from 'pages/HeroImages';
import Home from 'pages/Dashboard';
import OtherSubmission from 'pages/OtherSubmisson';

export const publicRoutes = (
  <Routes>
    <Route path="/login" element={<Auth />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

export const privateRoutes = (
  <SideMenu>
    <SiteContent>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/phones" element={<Phones />} />
        <Route path="/phone/:id" exact element={<Phone />} />
        <Route path="/submissions" exact element={<Submissions />} />
        <Route path="/submission/:id" element={<Submission />} />
        <Route path="/other-submission/:id" element={<OtherSubmission />} />
        <Route path="/add-phone" element={<PhoneForm />} />
        <Route path="/edit-phone/:id" element={<PhoneForm />} />
        <Route path="/users" element={<Users />} />
        <Route path="/add-user" element={<UserForm />} />
        <Route path="/edit-user/:id" element={<UserForm />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/add-partner" element={<PartnerForm />} />
        <Route path="/edit-partner/:id" element={<PartnerForm />} />
        <Route path="/addresses/:id" element={<Addresses />} />
        <Route path="/hero-images" element={<HeroImages />} />
        <Route path="/reports" exact element={<Reports />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </SiteContent>
  </SideMenu>
);
