import BaseService from './base.service';

export class Settings extends BaseService {
  async updateSettings(id, data) {
    const res = await this.axios.put(`/users/${id}`, data);
    return res.data;
  }
}

const settingsSerivce = new Settings();
export default settingsSerivce;
