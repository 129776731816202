import axios from 'axios';

// Utils
import configuration from 'config';
import notificationPopUp from './notificationPopUp';

const defaultConfig = {
  token: '',
  onSignOut: () => null,
  initLoading: () => null,
  finishLoading: () => null
};

let config = {
  ...defaultConfig
};

export const createAxiosInstance = ({ urlSufix = '' }) => {
  const API = axios.create({
    baseURL: `${configuration().baseUrl}${urlSufix}`
  });

  API.interceptors.request.use(
    (request) => {
      config.initLoading?.();
      if (!request.headers) {
        request.headers = {};
      }
      request.headers.Accept = 'application/json';

      const jwtToken = config.token;

      if (jwtToken) {
        // Automatically attach JWT for authenticated API requets
        request.headers.Authorization = `Bearer ${jwtToken}`;
      }

      if (request.method === 'POST' || request.method === 'PUT') {
        // Let the backend know we're sending JSON data
        request.headers['Content-Type'] = 'application/json';
      }
      return request;
    },
    (error) => {
      const message = error.response
        ? error.response.data.message
        : error.message;

      notificationPopUp({ description: message });

      config.finishLoading?.();
      throw error;
    }
  );

  API.interceptors.response.use(
    (response) => {
      config.finishLoading?.();
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        config.onSignOut?.(error.response.statusText);
      }

      const errorData = error.response?.data || {};

      const _error = {
        ...error,
        statusCode: errorData?.status,
        message: errorData?.message || error.message
      };

      const message = error.response
        ? error.response.data.message
        : error.message;

      notificationPopUp({ description: message });

      config.finishLoading?.();
      return Promise.reject(_error);
    }
  );

  return API;
};

export const configureAPI = (newConfig) => {
  config = { ...config, ...newConfig };
};

export default axios;
