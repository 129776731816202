import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select
} from 'antd';
import { useTranslation } from 'react-i18next';

// Services
import TextArea from 'antd/lib/input/TextArea';
import { getAddressValue } from 'utils/helper.util';
import deviceService from 'services/device.service';
import brandService from 'services/brand.service';
import partnerService from 'services/partner.service';

function CreateDevice({ visible, onClose, isOtherSubmission = false }) {
  // Hooks
  const submitButtonRef = useRef(null);
  const [createDeviceForm] = Form.useForm();
  const { t } = useTranslation('forms');

  const optionsPartnerOrClient = [
    {
      value: 'partner',
      label: t('common:partner')
    },
    {
      value: 'client',
      label: t('common:client')
    }
  ];

  // State
  const [brands, setBrands] = useState();
  const [brandDetails, setBrandDetails] = useState();
  const [confirmToSell, setConfirmToSell] = useState(false);
  const [deviceModels, setDeviceModels] = useState();
  const [deviceType, setDeviceType] = useState();
  const [partners, setPartners] = useState();
  const [partner, setPartner] = useState();
  const [isPartner, setIsPartner] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedDeviceType, setSelectedDeviceType] = useState();

  // Declaretion
  let deviceObject;
  if (brandDetails) {
    deviceObject = brandDetails.devices;
  }

  // Handlers
  const fetchBrands = async () => {
    const res = await brandService.getBrands();
    setBrands(res.rows);
  };

  const fetchDevices = async (selectedBrand) => {
    const res = await brandService.getBrandByName(selectedBrand);
    setBrandDetails(res);
  };

  const fetchPartners = async () => {
    if (isPartner) {
      const res = await partnerService.getPartners();
      setPartners(res.partners.rows);
    }
  };

  const handleSelectPartnerOrClient = async (value) => {
    if (value === 'partner') {
      return setIsPartner(true);
    }
    setIsPartner(false);
  };

  const handleCloseModal = () => {
    setConfirmToSell(false);
    onClose?.();
    createDeviceForm.resetFields();
    setIsPartner(true);
  };

  const handleAddressFormItems = (address) => {
    createDeviceForm.setFieldValue('address', address);
    createDeviceForm.setFieldValue('address_value', getAddressValue(address));
  };

  const handleSelectPartner = (value) => {
    const partner = partners.find((partner) => partner.id === value);
    setPartner(partner);

    if (partner && partner.addresses.length === 1) {
      const address = partner.addresses[0];
      handleAddressFormItems(address);

      createDeviceForm.setFieldValue('name', partner.name);
      createDeviceForm.setFieldValue('email', partner.email);
      createDeviceForm.setFieldValue('phone_number', address.phone_number_1);
      createDeviceForm.setFieldValue('iBan', partner.payment_method.value);
      createDeviceForm.setFieldValue('account_holder', partner.name);
    }
  };

  const handleSetAddress = (value, item) => {
    const address = partner.addresses.find(
      (address) => `${address.id}` === item.key
    );
    handleAddressFormItems(address);
  };

  const handleSelectBrand = async (value) => {
    await fetchDevices(value);
    setSelectedBrand(value);
  };

  const handleCreateDevice = async (data) => {
    await deviceService.createDevice(isOtherSubmission, data);
    createDeviceForm.resetFields();
    setConfirmToSell(false);
    onClose?.();
  };

  const handleSubmit = async (values) => {
    const hasCountry = values?.address?.country
      ? values.address.country
      : 'Switzerland';
    const constructData = {
      personal_information: {
        name: isPartner ? partner?.name : values.name,
        email: isPartner ? partner?.email : values.email,
        phone_number: isPartner
          ? partner.addresses[0]?.phone_number_1
          : values.phone_number,
        address: {
          street: isPartner ? values?.address?.address : values.address,
          zip: isPartner ? values?.address?.zip : values.zip,
          country: isPartner ? hasCountry : values.country,
          city: isPartner ? values?.address?.city : values.city
        },
        bank_information: {
          iBan: isPartner ? partner?.payment_method?.value : values.iBan,
          account_holder: isPartner ? partner?.name : values.name
        }
      },
      device_condition: {
        price: values?.price,
        note: isOtherSubmission ? values?.tell_for_condition : null
      },
      address_id: isPartner ? values.address.id : null,
      device_id: isOtherSubmission ? null : values?.device_model,
      partner_id: isPartner ? values?.partner_id : null
    };
    await handleCreateDevice(constructData);
  };

  // Effects
  useEffect(() => {
    const handleDeviceModels = () => {
      if (brandDetails) {
        const foundDeviceModel = deviceObject[selectedDeviceType];
        return setDeviceModels(foundDeviceModel);
      }
    };

    handleDeviceModels();
  }, [selectedDeviceType]);

  useEffect(() => {
    const handleDeviceType = () => {
      const devices = [];
      if (brandDetails) {
        for (const deviceType in deviceObject) {
          if (deviceObject[deviceType].length) {
            devices.push(deviceObject[deviceType][0]);
          }
        }
      }
      setDeviceType(devices);
    };

    handleDeviceType();
  }, [selectedBrand]);

  useEffect(() => {
    fetchPartners();
  }, [isPartner]);

  useEffect(() => {
    fetchBrands();
    createDeviceForm.setFieldValue('partner_or_client', 'partner');
  }, []);

  return (
    <Modal
      visible={visible}
      okText={t('containers:createDevice.sellDevice')}
      footer={null}
      onCancel={handleCloseModal}
    >
      <Divider>{t('containers:createDevice.sellDevice')}</Divider>
      <Form
        onFinish={handleSubmit}
        name="createDeviceForm"
        layout="vertical"
        form={createDeviceForm}
      >
        {!isOtherSubmission ? (
          <>
            <Form.Item
              name="brand_name"
              label={t('label.brand')}
              rules={[
                { required: true, message: t('requiredMessage.selectBrand') }
              ]}
            >
              <Select onChange={(value) => handleSelectBrand(value)}>
                {brands
                  && brands?.map((brand) => (
                    <Select.Option key={brand.id} value={brand.name}>
                      {brand.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('label.type')} name="device_type">
              <Select
                onChange={(value) => setSelectedDeviceType(value)}
                disabled={!selectedBrand}
              >
                {deviceType?.map((type) => (
                  <Select.Option value={type.name} key={type.name}>
                    {type.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('label.model')} name="device_model">
              <Select disabled={!selectedDeviceType}>
                {deviceModels?.map((model) => (
                  <Select.Option value={model.id} key={model.id}>
                    {model.model}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : (
          <Form.Item
            label={t('label.tellUsAboutDevice')}
            name="tell_for_condition"
            rules={[
              {
                required: true,
                message: t('requiredMessage.writeSomethingForDevice')
              }
            ]}
          >
            <TextArea />
          </Form.Item>
        )}

        <Form.Item
          label={t('label.price')}
          name="price"
          rules={[
            {
              required: true,
              message: t('requiredMessage.enterPriceForDevice')
            }
          ]}
        >
          <InputNumber
            style={{ width: '470px' }}
            placeholder="E.g 100"
            max={100000}
          />
        </Form.Item>
        <Form.Item
          label={t('label.partnerOrClient')}
          name="partner_or_client"
          rules={[
            {
              required: true,
              message: t('requiredMessage.selectPartnerOrClient')
            }
          ]}
        >
          <Select
            onChange={(value) => handleSelectPartnerOrClient(value)}
            defaultValue="partner"
          >
            {optionsPartnerOrClient?.map((item) => (
              <Select.Option value={item?.value} key={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {isPartner ? (
          <>
            <Form.Item
              label={t('label.choosePartner')}
              name="partner_id"
              rules={[
                {
                  required: true,
                  message: t('requiredMessage.selectPartnerOrClient')
                }
              ]}
            >
              <Select onChange={(value) => handleSelectPartner(value)}>
                {partners?.map((partner) => (
                  <Select.Option key={partner.id} value={partner.id}>
                    {partner.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('label.chooseAddress')} name="address" hidden />

            <Form.Item
              label={t('label.chooseAddress')}
              name="address_value"
              rules={[
                {
                  required: true,
                  message: t('requiredMessage.selectPartnerOrClient')
                }
              ]}
            >
              <Select
                disabled={partner?.addresses?.length <= 1 || !partner}
                onChange={(value, item) => handleSetAddress(value, item)}
              >
                {partner?.addresses?.map((address) => (
                  <Select.Option
                    key={address.id}
                    value={getAddressValue(address)}
                  >
                    {getAddressValue(address)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : null}
        {!isPartner && (
          <>
            <Form.Item
              label={t('label.name')}
              name="name"
              rules={[{ required: true, message: t('requiredMessage.name') }]}
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item
              label={t('label.city')}
              name="city"
              rules={[{ required: true, message: t('requiredMessage.city') }]}
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item
              label={t('label.address')}
              name="address"
              rules={[
                { required: true, message: t('requiredMessage.address') }
              ]}
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item
              label={t('label.country')}
              name="country"
              rules={[
                { required: true, message: t('requiredMessage.country') }
              ]}
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item
              label={t('label.zip')}
              name="zip"
              rules={[{ required: true, message: t('requiredMessage.name') }]}
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item
              label={t('label.email')}
              name="email"
              rules={[{ required: true, message: t('requiredMessage.email') }]}
            >
              <Input type="email" />
            </Form.Item>

            <Form.Item
              label={t('label.phoneNumber')}
              name="phone_number"
              rules={[{ required: true, message: t('requiredMessage.phone') }]}
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item
              label={t('label.iban')}
              name="iBan"
              maxLength="23"
              pattern=".{23}"
              rules={[{ required: true, message: t('requiredMessage.iban') }]}
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item
              label={t('label.accountHolder')}
              name="account_holder"
              rules={[
                { required: true, message: t('requiredMessage.accountHolder') }
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </>
        )}
        <Button htmlType="submit" hidden ref={submitButtonRef}>
          {t('common:submit')}
        </Button>
        <Popconfirm
          title={`${t('containers:createDevice.confirmSell')} ?`}
          placement="topRight"
          onConfirm={() => submitButtonRef.current?.click()}
          open={confirmToSell}
          onCancel={() => setConfirmToSell(false)}
          okText={t('common:yes')}
          htmlType="submit"
          cancelText={t('common:no')}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'end'
            }}
          >
            <Button onClick={handleCloseModal}>{t('common:close')}</Button>
            <Button type="primary" onClick={() => setConfirmToSell(true)}>
              {t('common:submit')}
            </Button>
          </div>
        </Popconfirm>
      </Form>
    </Modal>
  );
}

export default CreateDevice;
