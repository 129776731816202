import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Button, Card, Divider, Form, Input, Select, Alert } from 'antd';

// Services
import userService from 'services/user.service';

function UserForm() {
  // State
  const [user, setUser] = useState();
  const [addedUser, setAddedUser] = useState();
  // Hooks
  const { id } = useParams();
  const { t } = useTranslation('pages');
  const [form] = Form.useForm();
  const [changePasswordForm] = Form.useForm();

  // Handlers
  const fetchUser = async () => {
    if (!id) {
      return;
    }

    const res = await userService.getUser(id);
    if (res.users) {
      setUser(res.users);
      form.resetFields();
    }
  };

  const updateUser = async (id, data) => {
    await userService.updateUser(id, data);
  };

  const createUser = async (data) => {
    const res = await userService.createUser(data);
    setAddedUser(res.users);
  };

  const submitUserData = (values) => {
    const data = {
      user: {
        name: values.name,
        role_name: values.role_name
      }
    };

    if (id) {
      updateUser(id, data);
      form.resetFields();
      return;
    }

    data.user.email = values.email;

    createUser(data);
    form.resetFields();
  };

  const changePassword = async (values) => {
    const data = {
      user: {
        old_password: values.old_password,
        password: values.new_password,
        password_confirmation: values.confirm_password
      }
    };

    const res = await userService.changePassword(id, data);

    if (res) form.resetFields();
  };

  // Efffects
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Card
      title={id ? t('userForm.editUser') : t('userForm.addUser')}
      bordered={false}
    >
      {addedUser && (
        <Alert
          message={t('userForm.infoAddedUser')}
          description={
            <>
              <p>
                {`${t('forms:label.email')}: `}
                {addedUser.email}
              </p>
              <p>
                {`${t('forms:label.password')}: `}
                {addedUser.generated_password}
              </p>
            </>
          }
          type="success"
          showIcon
        />
      )}
      <Form
        form={form}
        initialValues={{ ...user }}
        onFinish={submitUserData}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label={t('forms:label.name')}
          rules={[{ required: true, message: t('forms:requiredMessage.name') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label={t('forms:label.email')}
          rules={[
            { required: true, message: t('forms:requiredMessage.email') }
          ]}
        >
          <Input type="email" disabled={id} />
        </Form.Item>
        <Form.Item
          name="role_name"
          label={t('forms:label.role')}
          rules={[{ required: true, message: t('forms:requiredMessage.role') }]}
        >
          <Select disabled={user && user.role_name === 'company'}>
            <Select.Option value="company">
              {t('userForm.follow')}
            </Select.Option>
            <Select.Option value="admin"> {t('userForm.admin')}</Select.Option>
          </Select>
        </Form.Item>
        <Button htmlType="submit" style={{ float: 'right' }}>
          {t('common:submit')}
        </Button>
      </Form>
      {user && (
        <>
          <Divider>{t('userForm.changePassword')}</Divider>
          <Form
            form={changePasswordForm}
            onFinish={changePassword}
            layout="vertical"
          >
            <Form.Item
              name="old_password"
              label={t('forms:label.oldPassword')}
              rules={[
                {
                  required: true,
                  message: t('forms:requiredMessage.oldPassword')
                }
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="new_password"
              label={t('forms:label.newPassword')}
              rules={[
                {
                  required: true,
                  message: t('forms:requiredMessage.newPassword')
                }
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label={t('forms:label.confirmPassword')}
              rules={[
                {
                  required: true,
                  message: t('forms:requiredMessage.confirmPassword')
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t('forms:requiredMessage.notSameAsNewPassword')
                    );
                  }
                })
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Button htmlType="submit" style={{ float: 'right' }}>
              {t('userForm.changePassword')}
            </Button>
          </Form>
        </>
      )}
    </Card>
  );
}

export default UserForm;
