import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import {
  Layout,
  Menu,
  Space,
  Input,
  Button,
  Image,
  Spin
} from 'antd';

import {
  HomeOutlined,
  SettingOutlined,
  LogoutOutlined,
  PhoneOutlined,
  UsergroupAddOutlined,
  SnippetsOutlined,
  FileImageOutlined,
  FileZipOutlined,
  ApartmentOutlined
} from '@ant-design/icons';

import { debounce } from 'lodash';

// Store
import { logout } from 'store/user';

// Utils
import { getSubmissionId } from 'utils/validation';
import notificationPopUp from 'utils/notificationPopUp';
// Services
import submissionService from 'services/submission.service';

// Components
import Logo from 'assets/images/verkauf_deinehandy_logo.png';

// Styles
import 'pages/Submissons/submissions.css';
import './menu.css';

const { Header, Footer, Sider } = Layout;

function SideMenu(props) {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation('pages');
  const location = useLocation();
  const dispatch = useDispatch();
  const date = new Date();
  const year = date.getFullYear();

  // State
  const [query, setQuery] = useState('');
  const [hideDropDown, setHideDropDown] = useState(false);
  const [activeKey, setActiveKey] = useState(location.pathname);
  const [loading, setLoading] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);

  // Handlers
  const handleFindSubmission = async (value) => {
    setLoading(true);

    if (!value || value.length !== 12) {
      return;
    }

    try {
      const { id, type } = getSubmissionId(value);

      if (!id || !type) {
        throw new Error(t('sideMenu.submissonNotFun'));
      }

      await submissionService.getSubmission(id, type);
      const prefix = type === 'SU' ? '/submission' : '/other-submission';
      navigate(`${prefix}/${id}`);
      setLoading(false);
    } catch (err) {
      notificationPopUp({ message: err.message });
    }
    setQuery('');
  };

  const debounceInput = debounce(handleFindSubmission, 1000);

  const handleGetSubmission = (e) => {
    debounceInput(e);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
    return handleGetSubmission(e.target.value);
  };

  useEffect(() => setActiveKey(location.pathname), [location]);

  const logo = (
    <Button
      onClick={() => navigate('/Phones.js')}
      type="link"
      size="small"
      style={{
        width: '100%',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Image src={Logo} alt="Logo" preview={false} height="30px" />
    </Button>
  );

  return (
    <Layout style={{ minHeight: '100vh', zIndex: 100 }}>
      <Header style={{ zIndex: 1, width: '100%', position: 'fixed' }}>
        {!hideLogo ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            {logo}
          </div>
        ) : null}
      </Header>
      <Sider
        theme="dark"
        breakpoint="lg"
        onBreakpoint={(breaked) => {
          setHideLogo(!breaked);
          setHideDropDown(!breaked);
        }}
        onCollapse={(collapsed) => setHideLogo(!collapsed)}
        collapsedWidth="0"
        style={{ height: '100%', position: 'fixed', zIndex: 100 }}
      >
        {logo}
        <Menu theme="dark" defaultSelectedKeys={activeKey} mode="inline">
          <Space direction="vertical" style={{ padding: '0 8px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: '5px',
                marginTop: '10px',
                marginBottom: '20px',
                position: 'relative'
              }}
            >
              <Input
                defaultValue={query}
                value={query}
                placeholder="Search..."
                autoFocus
                onChange={handleSearch}
                style={{ width: '100%' }}
              />
              {loading ? (
                <Spin
                  size="small"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '5px',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              ) : null}
            </div>
          </Space>
          <Menu.Item
            key="/"
            icon={<HomeOutlined />}
            onClick={() => navigate('/')}
          >
            {t('sideMenu.dashboard')}
          </Menu.Item>
          <Menu.Item
            key="/phones"
            icon={<PhoneOutlined />}
            onClick={() => navigate('/phones')}
          >
            {t('sideMenu.devices')}
          </Menu.Item>
          <Menu.Item
            key="/header-image"
            icon={<FileImageOutlined />}
            onClick={() => navigate('/hero-images')}
          >
            {t('sideMenu.homeHero')}
          </Menu.Item>
          <Menu.Item
            key="/submissions"
            icon={<FileZipOutlined />}
            onClick={() => navigate('/submissions?tab=submissions')}
          >
            {t('sideMenu.submissions')}
          </Menu.Item>
          <Menu.Item
            key="/users"
            icon={<UsergroupAddOutlined />}
            onClick={() => navigate('/users')}
          >
            {t('sideMenu.user')}
          </Menu.Item>
          <Menu.Item
            key="/partners"
            icon={<ApartmentOutlined />}
            onClick={() => navigate('/partners')}
          >
            {t('sideMenu.partner')}
          </Menu.Item>
          <Menu.Item
            key="/reports"
            icon={<SnippetsOutlined />}
            onClick={() => navigate('/reports')}
          >
            {t('sideMenu.reports')}
          </Menu.Item>
          <Menu.Item
            icon={<SettingOutlined />}
            key="/settings"
            onClick={() => navigate('/settings')}
          >
            {t('settings.title')}
          </Menu.Item>
          <Menu.Item
            icon={<LogoutOutlined />}
            key="/logout"
            onClick={() => dispatch(logout())}
          >
            {t('sideMenu.logut')}
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginTop: 60, marginLeft: hideDropDown ? '200px' : '0px' }}
      >
        {props.children}
        <Footer style={{ textAlign: 'center' }}>
          <a href="cohax.co">{`Cohax ${year} ©`}</a>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default SideMenu;
