import BaseService from './base.service';

export class Brand extends BaseService {
  async getBrands() {
    const res = await this.axios.get('/brands');
    return res.data.brands;
  }

  async getBrandByName(selectedBrand) {
    const res = await this.axios.get(`/brands-by-name/${selectedBrand}`);
    return res.data.brand;
  }
}

const brandService = new Brand();
export default brandService;
