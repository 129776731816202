import { createAxiosInstance } from 'utils/api';

class BaseService {
  constructor(_urlSufix = '') {
    this.urlSufix = _urlSufix;
    this.axios = createAxiosInstance({ urlSufix: this.urlSufix });
  }
}

export default BaseService;
