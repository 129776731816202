import { useState } from 'react';

import { Card } from 'antd';

import { useTranslation } from 'react-i18next';

import { useNavigate, useLocation } from 'react-router-dom';

// Partials
import OtherSumbissions from './_OtherSubmissions';
import Submissions from './_Submissions';

function index() {
  // Hooks
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation('pages');

  // Declaration
  let tab = new URLSearchParams(search).get('tab');
  tab = tab || 'submissions';
  const [key, setKey] = useState(tab);

  const tabList = [
    { key: 'submissions', tab: t('submisson.title') },
    { key: 'other-submissions', tab: t('otherSubmisson.title') }
  ];

  // Handlers
  const onTabChange = (_key) => {
    setKey(_key);
    navigate(`/submissions?tab=${_key}`);
  };

  return (
    <Card
      style={{ width: '100%' }}
      tabList={tabList}
      activeTabKey={key}
      onTabChange={onTabChange}
    >
      {key === 'submissions' && <Submissions />}
      {key === 'other-submissions' && <OtherSumbissions />}
    </Card>
  );
}

export default index;
