import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
  Tag,
  Divider,
  PageHeader,
  Typography,
  List,
  Button,
  Alert
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// Services
import userService from 'services/user.service';

function Users() {
  // State
  const [users, setUsers] = useState();
  const [deletedUser, setDeletedUser] = useState();

  // Hooks
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation('pages');

  // Declaration
  let page = new URLSearchParams(search).get('page');
  page = page || 1;

  const fetchUsers = async () => {
    const params = `?limit=10&page${page}`;

    const res = await userService.getUsers(params);

    if (res.users) {
      setUsers(res.users);
    }
  };

  const deleteUser = async (user) => {
    const res = await userService.deleteUser(user.id);

    if (res.data) {
      setDeletedUser(user);
    }
    fetchUsers();
  };

  // Effects
  useEffect(() => {
    fetchUsers();
  }, [page]);

  return (
    <>
      <PageHeader
        title={t('user.title')}
        extra={[
          <Button
            key="add_category"
            icon={<PlusOutlined />}
            onClick={() => navigate('/add-user')}
          >
            {t('user.title')}
          </Button>
        ]}
      />
      {deletedUser && (
        <Alert
          message={`${deletedUser.name} ${t('user.deletedInfo')}`}
          description=""
          type="warning"
          showIcon
        />
      )}
      <Divider />
      {users && (
        <List
          size="default"
          style={{ textAlign: 'left' }}
          itemLayout="horizontal"
          dataSource={users.rows}
          pagination={{
            defaultCurrent: 1,
            total: users.total_data,
            defaultPageSize: 10,
            pageSize: 10,
            onChange: (page) => navigate(`?page=${page}`)
          }}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => navigate(`/edit-user/${item.id}`)}
                >
                  <Typography.Text type="warning">
                    <EditOutlined /> {t('partner.edit')}
                  </Typography.Text>
                </Button>,
                <Button type="link" onClick={() => deleteUser(item)}>
                  <Typography.Text type="danger">
                    <DeleteOutlined /> {t('common:delete')}
                  </Typography.Text>
                </Button>
              ]}
            >
              <List.Item.Meta
                title={<Tag color="volcano">{item.name}</Tag>}
                description={`Position: ${item.role_name.toLocaleUpperCase()}`}
              />
            </List.Item>
          )}
        />
      )}
    </>
  );
}

export default Users;
