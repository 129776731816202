import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import configuration from 'config/index';
import userReducer from './user';
import layoutReducer from './layout';

import api from './middleware/api';

const store = configureStore({
  devTools: configuration().appEnv !== 'production',
  reducer: {
    user: userReducer,
    layout: layoutReducer
  },
  middleware: [...getDefaultMiddleware(), api]
});

export default store;
