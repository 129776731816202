export const getSubmissionId = (id) => {
  try {
    const found = id.match(/(SU|OD)\d+/gi);
    const foundedId = found[0].match(/(\d+)/)[0];
    const foundedString = found[0].match(/[a-zA-Z]/gi).join('');

    return { id: parseInt(foundedId), type: foundedString };
  } catch {
    return { id: undefined, type: undefined };
  }
};

export default getSubmissionId;
