import React, { useState, useEffect, useRef } from 'react';

import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Modal,
  Select
} from 'antd';

import { useTranslation } from 'react-i18next';

// Services
import reportsService from 'services/reports.service';
import partnerService from 'services/partner.service';

function GenerateReport({ visible, onClose }) {
  // State
  const [partners, setPartners] = useState();
  const [allTimeReport, setAllTimeReport] = useState(false);
  const [generateReportForm] = Form.useForm();

  // Hooks
  const submitButtonRef = useRef(null);
  const { t } = useTranslation('containers');

  // Declaration
  const dateFormat = 'DD/MM/YYYY';

  // Handlers
  const fetchPartners = async () => {
    const res = await partnerService.getPartners();
    setPartners(res.partners?.rows);
  };

  const handleOnChange = (e) => {
    setAllTimeReport(e.target?.checked);
  };

  const handleOnChangeDateFrom = (date) => {
    generateReportForm.setFieldValue('date_from', date[0].toDate());
    generateReportForm.setFieldValue('date_to', date[1].toDate());
  };

  const handleCloseModal = () => {
    generateReportForm.resetFields();
    onClose?.();
  };

  const handleSubmit = async (data) => {
    await reportsService.createReport(data, allTimeReport);
    handleCloseModal();
  };

  // Effects
  useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={handleCloseModal}
      onOk={() => submitButtonRef.current?.click()}
    >
      <Divider>{t('generateReports.title')}</Divider>
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        name="generateReportForm"
        form={generateReportForm}
      >
        <Form.Item
          label={t('forms:label.choosePartner')}
          name="partner_id"
          rules={[
            { required: true, message: t('forms:requiredMessage.partner') }
          ]}
        >
          <Select>
            {partners?.map((partner) => (
              <Select.Option value={partner.id}>{partner?.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('forms:label.chooseTypeOfReport')}
          name="type"
          rules={[
            {
              required: true,
              message: t('forms:requiredMessage.chooseTypeOfReport')
            }
          ]}
        >
          <Select>
            <Select.Option value="other-submission">
              Other Submisson
            </Select.Option>
            <Select.Option value="submission">Submisson</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="date_from" hidden />
        <Form.Item name="date_to" hidden />

        <Form.Item
          label={t('forms:label.selectDates')}
          name="range_date"
          rules={[
            {
              required: !allTimeReport,
              message: t('forms:requiredMessage.selectDates')
            }
          ]}
        >
          <DatePicker.RangePicker
            onChange={handleOnChangeDateFrom}
            disabled={allTimeReport}
            style={{ width: '100%' }}
            format={dateFormat}
            size="large"
          />
        </Form.Item>

        <Form.Item name="all_time" valuePropName="checked">
          <Checkbox onChange={handleOnChange}>
            {t('generateReports.allTime')}
          </Checkbox>
        </Form.Item>
        <Button htmlType="submit" hidden ref={submitButtonRef}>
          {t('common:submit')}
        </Button>
      </Form>
    </Modal>
  );
}

export default GenerateReport;
