import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import moment from 'moment';

// Utils
import { filterObject } from 'utils/helper.util';

const useQueryHook = () => {
  const [params, setParams] = useState(null);

  const { search } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // Declaration
  const _page = +new URLSearchParams(search).get('page') || 1;

  const isOtherSubmissonTab = new URLSearchParams(search).get('tab');

  const includeStirng = isOtherSubmissonTab === 'other-submissions'
    ? 'accessKey;status'
    : 'device;device.brand;accessKey;status';

  const initialParams = {
    limit: 10,
    page: _page,
    include: includeStirng,
    append: 'code'
  };

  const statusValue = form.getFieldValue('status');
  const searchValue = form.getFieldValue('search');
  const rangeValue = form.getFieldValue('range');

  const dateFrom = rangeValue && rangeValue?.length > 0
    ? rangeValue[0].format('YYYY-MM-DD')
    : '';
  const dateTo = rangeValue && rangeValue?.length > 0
    ? rangeValue[1].format('YYYY-MM-DD')
    : '';

  const updateParams = async ({ params, url = '' }, shouldNavigate = true) => {
    if (!params.sortByAsc && !params.sortByDesc) {
      params.sortByDesc = 'id';
    }

    setParams({ ...params });
    if (shouldNavigate) navigate(url);
  };

  // Handlers
  const setQueryParams = (param) => {
    const _params = {
      ...initialParams,
      filter_groups: []
    };

    if (param.searchValue) {
      _params.filter_groups.push({
        filters: filterObject('clientName', 'ct', param.searchValue)
      });

      form.setFieldValue('search', param.searchValue);
    }

    if (param.statusValue) {
      if (param.statusValue !== 'all') {
        _params.filter_groups.push({
          filters: filterObject('status_name', 'eq', param.statusValue)
        });
      }

      form.setFieldValue('status', param.statusValue);
    }

    if (param.dateFrom || param.dateTo) {
      const filters = [];
      let [_dateFrom, _dateTo] = ['', ''];

      if (param.dateFrom) {
        _dateFrom = moment(param.dateFrom);
        filters.push(filterObject('created_at', 'gte', param.dateFrom));
      }

      if (param.dateTo) {
        _dateTo = moment(param.dateTo);
        filters.push(filterObject('created_at', 'lte', param.dateTo));
      }

      form.setFieldValue('range', [_dateFrom, _dateTo]);

      _params.filter_groups.push({
        filters
      });
    }

    updateParams({ params: _params }, false);

    return _params;
  };

  useEffect(() => {
    const getSearchValue = new URLSearchParams(search).get('search');
    const getStatus = new URLSearchParams(search).get('status');
    const getDateFrom = new URLSearchParams(search).get('dateFrom');
    const getDateTo = new URLSearchParams(search).get('dateTo');

    const setQueryValues = {
      searchValue: getSearchValue,
      statusValue: getStatus,
      dateFrom: getDateFrom,
      dateTo: getDateTo
    };

    setQueryParams(setQueryValues);
  }, []);

  return {
    form,
    setQueryParams,
    params,
    updateParams,
    _page,
    statusValue,
    searchValue,
    dateFrom,
    dateTo,
    rangeValue
  };
};

export default useQueryHook;
