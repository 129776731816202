export const storageOptionsData = [
  { label: '32 GB', value: '32 GB' },
  { label: '64 GB', value: '64 GB' },
  { label: '128 GB', value: '128 GB' },
  { label: '256 GB', value: '256 GB' },
  { label: '512 GB', value: '512 GB' }
];

export const colorOptionsData = [
  { label: 'WEISS', value: 'white' },
  { label: 'SCHWARZ', value: 'black' },
  { label: 'SILBER', value: 'silver' },
  { label: 'GOLD', value: 'gold' }
];
