import BaseService from './base.service';

export class User extends BaseService {
  async getUsers(params) {
    const res = await this.axios.get('/users', { params });
    return res.data;
  }

  async getUser(id) {
    const res = await this.axios.get(`/users/${id}`);
    return res.data;
  }

  async deleteUser(id) {
    const res = await this.axios.delete(`/users/${id}`);
    return res.data;
  }

  async createUser(data) {
    const res = await this.axios.post('/users', data);
    return res.data;
  }

  async updateUser(id, data) {
    const res = await this.axios.put(`/users/${id}`, data);
    return res.data;
  }

  async changePassword(id, data) {
    const res = await this.axios.put(`/admin-change-password/${id}`, data);
    return res.data;
  }

  async resetPassword(id) {
    const res = await this.axios.put(`/reset-password/${id}`);
    return res.data;
  }
}

const userService = new User();
export default userService;
