import BaseService from './base.service';

export class Upload extends BaseService {
  async uploadFile(method, path, data) {
    const res = await this.axios(path, { method, data });
    return res.data;
  }
}

const uploadService = new Upload();
export default uploadService;
