import axios from 'axios';

import configuration from 'config';

import * as actions from '../types/api';

const api = ({ dispatch }) => (next) => async (action) => {
  if (action.type !== actions.apiCallBegan.type) return next(action);

  const { url, method, data, headers, onStart, onSucces, onError } = action.payload;

  if (onStart) dispatch({ type: onStart });

  next(action);

  try {
    const response = await axios.request({
      baseURL: configuration().baseUrl,
      url,
      method,
      data,
      headers
    });

    dispatch(actions.apiCallSucces(response.data));
    if (onSucces) {
      dispatch({ type: onSucces, payload: { data: response.data } });
    }
  } catch (error) {
    dispatch(actions.apiCallFailed(error.message));
    if (onError) dispatch({ type: onError, payload: error.message });
  }
};

export default api;
