import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadUser } from './store/user';

import Authorization from './router/Authorization';

import Loader from './components/Loader';

import 'antd/dist/antd.css';
import './App.css';

function App() {
  const dispatch = useDispatch();

  useEffect(() => dispatch(loadUser()), [dispatch]);

  return (
    <>
      <Loader />
      <Authorization />
    </>
  );
}

export default App;
