import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Space, Button, Table, Tag, Popconfirm, InputNumber, Card } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

// Hooks
import FilterForm from 'containers/FilterForm';
import useQueryHook from 'hooks/useQueryHook';

// Utils
import deviceService from 'services/device.service';
import submissionService from 'services/submission.service';
import { formatDateWithHour } from 'utils/data.utils';

// Config
import config from 'config';

// Containers
import Status from 'containers/Status';

// Style
import './submissions.css';

// Components
import CreateDevice from 'containers/CreateDevice';

function Submissions() {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation('pages');
  const {
    params,
    updateParams,
    _page,
    searchValue,
    dateFrom,
    dateTo,
    statusValue
  } = useQueryHook();

  // State
  const [createDeviceModal, setCreateDeviceModal] = useState(false);
  const [submissions, setSubmissions] = useState();
  const [submissionPrice, setSubmissionPrice] = useState({});
  const [statuses, setStatuses] = useState([]);

  // Handlers
  const handleGoToPage = (page) => {
    const url = `?tab=submissions&page=${page}${
      searchValue ? `&search=${searchValue}` : ''
    }${statusValue ? `&status=${statusValue}` : ''}${
      dateFrom ? `&dateFrom=${dateFrom}` : ''
    }${dateTo ? `&dateTo=${dateTo}` : ''}`;

    updateParams({ params: { ...params, page }, url });
  };

  const fetchSubmissions = async (_params) => {
    if (!_params) return;
    const data = await submissionService.getSubmissions({ ..._params });
    if (data.submissions.total_data / 10 < 1 && params.page > 1) {
      return handleGoToPage(1);
    }
    setSubmissions(data.submissions);
  };

  const handleDelete = async (id) => {
    const res = await submissionService.deleteSubmission(id);
    if (res) fetchSubmissions({ ...params });
  };

  const handleSort = async (direction, field, key) => {
    const sortDirection = direction === 'ascend' ? 'sortByAsc' : 'sortByDesc';

    const _params = {
      ...params
    };

    delete _params.sortByDesc;
    delete _params.sortByAsc;

    const keys = key.split('.');
    const type = keys[keys.length ? keys.length - 1 : 0];
    _params[sortDirection] = type === 'col' ? keys[0] : field.join('->');

    updateParams({ params: _params }, false);
  };

  const handleGetIdEditablePrice = (id) => !!submissionPrice[id];

  const handleOnChangePrice = async (submissionId) => {
    const oldPriceState = { ...submissionPrice };
    const _submissionPrice = oldPriceState[submissionId];
    if (!_submissionPrice) {
      return;
    }

    await deviceService.editablePrice({
      submissionId,
      price: _submissionPrice
    });

    delete oldPriceState[submissionId];
    setSubmissionPrice({ ...oldPriceState });
  };

  const handleChangePriceInput = (submissionId, price) => {
    const oldPriceState = { ...submissionPrice };

    oldPriceState[submissionId] = price;

    setSubmissionPrice({ ...oldPriceState });
  };

  const onChange = async (pagination, filters, sorter) => {
    if (Object.prototype.hasOwnProperty.call(sorter, 'column')) {
      await handleSort(sorter.order, sorter.field, sorter.columnKey);
    }
  };

  const fetchStatuses = async () => {
    const res = await submissionService.getStatuses();
    if (res.statuses) {
      setStatuses(res.statuses.rows);
    }
  };

  const downloadPdf = (id, access_key) => {
    window.location.href = `${
      config().baseUrl
    }/view-submission-pdf/${id}?access_key=${access_key}`;
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    fetchSubmissions(params);
  }, [params, _page]);

  const columns = [
    {
      title: t('table.barcode'),
      key: 'code',
      dataIndex: 'code',
      render: (code) => <Tag>{code}</Tag>
    },
    {
      title: t('table.name'),
      dataIndex: ['personal_information', 'name'],
      key: 'name.json',
      sorter: () => true
    },
    {
      title: t('table.brand'),
      dataIndex: ['device', 'brand', 'name'],
      key: 'brand.relation'
    },
    {
      title: t('table.device'),
      dataIndex: 'device',
      key: 'device',
      render: (device) => `${device?.name} ${device?.model}`
    },
    {
      title: t('table.price'),
      key: 'price.col',
      sorter: () => true,
      render: (data) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
            justifyContent: 'start'
          }}
        >
          <InputNumber
            defaultValue={data.price}
            onChange={(price) => handleChangePriceInput(data.id, price)}
          />
          <Popconfirm
            title={`${t('submisson.confirmChangePrice')} ?`}
            defaultValue={data.price}
            description="Open Popconfirm with Promise"
            align="topLeft"
            onConfirm={() => handleOnChangePrice(data.id)}
          >
            {handleGetIdEditablePrice(data.id) && (
              <Button type="link" size="large">
                <CheckCircleOutlined />
              </Button>
            )}
          </Popconfirm>
        </div>
      )
    },
    {
      title: t('table.status'),
      key: 'status_name',
      render: (data) => (
        <Status
          onSubmit={() => null}
          title={data.status_name}
          id={data.id}
          statuses={statuses}
        />
      )
    },
    {
      title: t('table.soldAt'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (createdAt) => (
        <span style={{ width: '120px', display: 'flex' }}>
          {formatDateWithHour(createdAt)}
        </span>
      )
    },
    {
      title: t('table.actions'),
      key: 'actions',
      render: (data) => (
        <Space>
          <Button
            type="link"
            onClick={() => navigate(`/submission/${data.id}`)}
          >
            {t('common:view')}
          </Button>
          <Button
            type="link"
            onClick={() => downloadPdf(data.id, data.access_key.key)}
          >
            {`PDF ${t('common:download')}`}
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={`${t('common:delete')} ?`}
            onConfirm={() => handleDelete(data.id)}
            okText={t('common:yes')}
            cancelText={t('common:no')}
          >
            <Button type="link" danger>
              {t('common:delete')}
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <Card bordered={false}>
      <FilterForm
        setCreateDeviceModal={setCreateDeviceModal}
        updateParams={updateParams}
      />

      <CreateDevice
        visible={createDeviceModal}
        onClose={() => setCreateDeviceModal(false)}
      />

      {submissions && (
        <Table
          dataSource={submissions.rows}
          rowKey="id"
          columns={columns}
          onChange={onChange}
          sortDirections={['descend', 'ascend', 'descend']}
          pagination={{
            defaultCurrent: _page || 1,
            total: submissions.total_data,
            defaultPageSize: 10,
            pageSize: 10,
            onChange: (page) => handleGoToPage(page)
          }}
        />
      )}
    </Card>
  );
}

export default Submissions;
