import { format, parseISO } from 'date-fns';

const DATE_FORMAT = 'dd.MM.yyyy';
const DATE_FORMAT_WITH_HOUR = 'dd.MM.yyyy / HH:mm';

export const formatDateWithHour = (date) => {
  if (!date) return '';

  let _date = date;

  if (typeof date === 'string') {
    _date = parseISO(date);
  }

  return format(_date, DATE_FORMAT_WITH_HOUR);
};

export const formatDate = (date) => {
  if (!date) return '';
  let _date = date;
  if (typeof date === 'string') {
    _date = parseISO(date);
  }
  return format(_date, DATE_FORMAT);
};
