import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Divider, Space, Card } from 'antd';

import './submission.css';
import submissionService from 'services/submission.service';

function OtherSubmission() {
  const { id } = useParams();
  const [submission, setSubmission] = useState();

  const fetchSubmission = async () => {
    if (!id) return;

    const otherSubmission = await submissionService.getOtherSubmission(id);
    setSubmission(otherSubmission.submissions);
  };

  useEffect(() => {
    fetchSubmission();
  }, []);

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Straße', dataIndex: ['address', 'street'], key: 'street' },
    { title: 'die PLZ', dataIndex: ['address', 'zip'], key: 'zip' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Telefonnummer', dataIndex: 'phone_number', key: 'phone_number' },
    {
      title: 'Notiz',
      dataIndex: ['additional', 'note'],
      key: 'note',
      responsive: ['lg']
    },
    {
      title: 'Wie haben sie von uns erfahren?',
      dataIndex: ['additional', 'marketing_information'],
      key: 'marketing_information',
      responsive: ['lg']
    },
    {
      title: 'iBan-Nummer',
      dataIndex: ['bank_information', 'iBan'],
      key: 'iBan'
    },
    {
      title: 'Kontoinhaber',
      dataIndex: ['bank_information', 'account_holder'],
      key: 'account_holder'
    }
  ];

  return (
    <Space direction="vertical">
      <Divider orientation="left">Persönliche Informationen</Divider>
      <Table
        dataSource={[submission?.personal_information]}
        columns={columns}
        rowKey="email"
        pagination={false}
      />
      <Card>
        <p>{submission?.device_condition.note}</p>
      </Card>
    </Space>
  );
}

export default OtherSubmission;
