import BaseService from './base.service';

export class Partner extends BaseService {
  async getPartners(params) {
    const res = await this.axios.get('/partners', { params });
    return res.data;
  }

  async getPartner(id) {
    const params = {
      include: 'owner;image;paymentMethod',
      append: 'addresses'
    };

    const res = await this.axios.get(`/partners/${id}`, { params });

    return res.data;
  }

  async createPartner(data) {
    const res = await this.axios.post('/partners', { partner: data });
    return res.data;
  }

  async updatePartner(id, data) {
    const res = await this.axios.put(`/partners/${id}`, { partner: data });
    return res.data;
  }

  async deletePartner(id) {
    await this.axios.delete(`/partners/${id}`);
  }
}

const partnerService = new Partner();
export default partnerService;
