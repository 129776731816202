import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// EN locales
import addressEn from './locales/en/address.json';
import formsEn from './locales/en/forms.json';
import commonEn from './locales/en/common.json';
import containersEn from './locales/en/containers.json';
import statusEn from './locales/en/status.json';
import pagesEn from './locales/en/pages.json';

// DE locales
import addressDe from './locales/de/address.json';
import formsDe from './locales/de/forms.json';
import commonDe from './locales/de/common.json';
import containersDe from './locales/de/containers.json';
import statusDe from './locales/de/status.json';
import pagesDe from './locales/de/pages.json';

// Config
import configuration from './config';

const resources = {
  en: {
    address: addressEn,
    forms: formsEn,
    common: commonEn,
    containers: containersEn,
    status: statusEn,
    pages: pagesEn
  },
  de: {
    address: addressDe,
    forms: formsDe,
    common: commonDe,
    containers: containersDe,
    status: statusDe,
    pages: pagesDe
  }
};

// i18N Initialization
i18n.use(initReactI18next).init({
  resources,
  lng: configuration().appLanguage,
  fallbackLng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  },
  ns: [
    'translation',
    'address',
    'common',
    'containers',
    'forms',
    'status',
    'pages'
  ]
});

export default i18n;
