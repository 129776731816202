import { Button, DatePicker, Select, Form, Input, Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

// Data
import { statusOptions } from 'assets/data/statuses';

// Hooks
import useQueryHook from 'hooks/useQueryHook';

const { RangePicker } = DatePicker;

function FilterForm({
  setCreateDeviceModal,
  updateParams,
  otherSubmission = false
}) {
  // Hooks
  const data = useQueryHook(otherSubmission);
  const { t } = useTranslation('containers');
  const { searchValue, rangeValue, statusValue, params, _page, form } = data;

  const { Panel } = Collapse;

  // Handlers
  const handleCleanSearch = () => {
    form.resetFields();

    const _params = {
      ...params
    };

    delete _params.filter_groups;

    const url = `?tab=submissions${_page ? `&page=${_page}` : ''}`;

    return updateParams({ params: _params, url });
  };

  const formSubmit = (values) => {
    const { search, status, range } = values;

    const _dateFrom = range && range?.length > 0 ? range[0].format('YYYY-MM-DD') : '';
    const _dateTo = range && range?.length > 0 ? range[1].format('YYYY-MM-DD') : '';

    const _params = {
      ...params,
      filter_groups: [
        {
          filters: [
            {
              column: 'clientName',
              operator: 'ct',
              value: search
            }
          ]
        },
        {
          filters: [
            {
              column: 'status_name',
              operator: 'eq',
              value: status === 'all' ? '' : status
            }
          ]
        },
        {
          filters: [
            {
              column: 'created_at',
              value: _dateFrom,
              operator: 'gte'
            },
            {
              column: 'created_at',
              value: _dateTo,
              operator: 'lte'
            }
          ]
        }
      ]
    };

    const url = otherSubmission
      ? `?tab=other-submissions${_page ? `&page=${_page}` : ''}${
        search ? `&search=${search}` : ''
      }${status ? `&status=${status}` : ''}${
        _dateFrom ? `&dateFrom=${_dateFrom}` : ''
      }${_dateTo ? `&dateTo=${_dateTo}` : ''}`
      : `?tab=submissions${_page ? `&page=${_page}` : ''}${
        search ? `&search=${search}` : ''
      }${status ? `&status=${status}` : ''}${
        _dateFrom ? `&dateFrom=${_dateFrom}` : ''
      }${_dateTo ? `&dateTo=${_dateTo}` : ''}`;

    return updateParams({ params: _params, url });
  };

  return (
    <Collapse ghost>
      <Panel
        header="Filters"
        key="0"
        extra={
          <Button
            style={{ justifySelf: 'flex-end' }}
            type="primary"
            onClick={() => setCreateDeviceModal(true)}
          >
            {t('createDevice.title')}
          </Button>
        }
      >
        <Form form={form} onFinish={formSubmit}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'center'
            }}
          >
            <Form.Item name="range">
              <RangePicker />
            </Form.Item>

            <Form.Item name="status">
              <Select
                placeholder={t('forms:placeHolder.status')}
                options={statusOptions(t)}
              />
            </Form.Item>

            <Form.Item name="search">
              <Input
                placeholder={t('forms:placeHolder.search')}
                suffix={<SearchOutlined />}
              />
            </Form.Item>

            <Button htmlType="submit">{t('filterForm.filter')}</Button>
            {searchValue || statusValue || rangeValue ? (
              <Button onClick={() => handleCleanSearch()} type="button">
                X
              </Button>
            ) : null}
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
}

export default FilterForm;
