import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Descriptions, Divider, Tag, Button, Table, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

// Services
import deviceService from 'services/device.service';

// Utils
import { getQuestionsPricing } from 'utils/options';

function Phone() {
  // State
  const [device, setDevice] = useState();

  // Hooks
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation('pages');

  // Handlers
  const fetchDevice = async () => {
    if (!id) {
      return;
    }

    const fetchDevice = await deviceService.getDevice(id);

    if (fetchDevice && fetchDevice.devices) {
      const deviceData = fetchDevice.devices;
      const questions = getQuestionsPricing(deviceData);

      const data = {
        id: deviceData.id,
        brand_name: deviceData.brand.name,
        name: deviceData.name,
        model: deviceData.model,
        full_name: `${deviceData.brand.name} ${deviceData.name}${deviceData.model}`,
        image_path: deviceData.image.path,
        options: deviceData.options,
        questions
      };
      setDevice(data);
    }
  };

  // Effects
  useEffect(() => {
    fetchDevice();
  }, []);

  const optionsColumn = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    {
      title: 'Options',
      key: 'options',
      render: (item) => {
        const data = item.options.length ? item.options : item.options.data;
        const type = item.options.length ? 'colors' : 'storage';

        return data.map((i, index) => (
          <div key={`${i.price}-${i.option}-${index + 1}`}>
            {type === 'colors' ? (
              <Tag>{i}</Tag>
            ) : (
              <>
                {i.option} {i.price && <Tag>{i.price} .-</Tag>}
              </>
            )}
            {data.length !== index + 1 && <Divider type="vertical" />}
          </div>
        ));
      }
    }
  ];

  const questionColumn = [
    { title: 'Questions', dataIndex: 'question', key: 'question' }
  ];

  return (
    <div>
      {device && (
        <Space direction="vertical">
          <Divider orientation="left">{t('phone.generalInfo')}</Divider>
          <Descriptions
            layout="horizontal"
            extra={
              <Button
                icon={<EditOutlined />}
                type="link"
                onClick={() => navigate('/edit-phone/1')}
              >
                {t('partner.edit')}
              </Button>
            }
          >
            <Descriptions.Item label={t('table.brand')}>
              {device.brand_name}
            </Descriptions.Item>
            <Descriptions.Item label={t('forms:label.name')}>
              {device.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('forms:label.model')}>
              {device.model}
            </Descriptions.Item>
          </Descriptions>

          <Divider orientation="left">{t('phone.deviceOptions')}</Divider>

          <Table
            dataSource={device.options}
            columns={optionsColumn}
            pagination={false}
          />

          <Table
            dataSource={device.questions}
            columns={questionColumn}
            pagination={false}
            rowKey="id"
            expandable={{
              // eslint-disable-next-line react/no-unstable-nested-components
              expandedRowRender: (item) => (
                <ul>
                  {item.answers.map((i, index) => (
                    <li key={`${i.answer.answer}-${index + 1}`}>
                      {i.answer.answer} <Tag>{i.charge} .-</Tag>
                    </li>
                  ))}
                </ul>
              ),
              defaultExpandAllRows: true
            }}
          />
        </Space>
      )}
    </div>
  );
}

export default Phone;
