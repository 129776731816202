import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Tag, PageHeader, List, Button, Alert, Modal, Form, Card } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import addressesService from 'services/addresses.service';
import AddAddress from 'containers/AddAddress';

function Addresses() {
  const { t } = useTranslation('pages');
  const { id } = useParams();
  const [addresses, setAddresses] = useState([]);
  const [deletedAddress, setDeletedAddress] = useState();
  const [addAddress, setAddAddress] = useState(false);
  const [addressForm] = Form.useForm();

  const navigate = useNavigate();

  const { search } = useLocation();
  let page = new URLSearchParams(search).get('page');
  page = page || 1;

  const fetchAddresses = async () => {
    const params = `?limit=10&page${page}`;

    const res = await addressesService.getAddresses(id, params);

    if (res.addresses) {
      setAddresses(res.addresses);
    }
  };

  const deleteAddress = async (address) => {
    if (addresses.rows.length === 1) {
      return;
    }

    const res = await addressesService.deleteAddreses(address.id);
    setDeletedAddress(res.address);

    fetchAddresses();
  };

  const submitAddressData = async (values) => {
    const data = {
      address: {
        relationship_id: id,
        ...values
      }
    };

    const res = await addressesService.updateAddress(data);

    const newAddresses = addresses;
    newAddresses.rows.push(res.data.address);

    setAddresses(newAddresses);
    setAddAddress(false);
    addressForm.resetFields();
  };

  useEffect(() => {
    fetchAddresses();
  }, [page]);

  return (
    <>
      <PageHeader title={t('addresses.title')} />
      {deletedAddress && (
        <Alert
          message={`${deletedAddress.address} was deleted successfully`}
          type="warning"
          showIcon
        />
      )}

      <Modal
        visible={addAddress}
        footer={null}
        onCancel={() => setAddAddress(false)}
      >
        <AddAddress
          submitAddressData={submitAddressData}
          addressForm={addressForm}
        />
      </Modal>
      <Card
        bordered={false}
        size="small"
        extra={
          <Button onClick={() => setAddAddress(true)} type="primary">
            {t('address:addAddress.submitAddress')}
          </Button>
        }
      >
        <List
          size="default"
          style={{ textAlign: 'left' }}
          itemLayout="horizontal"
          dataSource={addresses.rows}
          pagination={{
            defaultCurrent: 1,
            total: addresses.total_data,
            defaultPageSize: 10,
            pageSize: 10,
            onChange: (_page) => navigate(`?page=${_page}`)
          }}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => deleteAddress(item)}
                  disabled={addresses.rows.length === 1}
                  danger
                >
                  <DeleteOutlined />
                </Button>
              ]}
            >
              <List.Item.Meta
                title={<Tag color="volcano">{item.address}</Tag>}
                description={`${t('common:city')}: ${item.city} | ${t(
                  'common.zip'
                )}: ${item.zip}`}
              />
            </List.Item>
          )}
        />
      </Card>
    </>
  );
}

export default Addresses;
