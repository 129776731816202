import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Card, Table, Space, Button, Input, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// Services
import deviceService from 'services/device.service';

function Phones() {
  // State
  const [devices, setDevices] = useState();
  const [deletedDevice, setDeleteDevice] = useState();

  // Hooks
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation('pages');

  // Decalretion
  const page = +new URLSearchParams(search).get('page') || 1;
  const filter = new URLSearchParams(search).get('filter[search]');

  const [params, setParams] = useState({
    limit: 10,
    page,
    include: 'brand:id,name',
    append: 'deletable',
    filter
  });

  // Handlers
  const fetchDevices = async () => {
    const _params = params;

    if (filter) {
      _params['filter[search]'] = filter;
    }

    delete _params.filter;

    const res = await deviceService.getDevices(_params);
    if (res.rows) setDevices(res);
  };

  const deleteDevice = (item) => {
    deviceService.deleteDevice(item.id);
    setDeleteDevice(item);
    fetchDevices();
  };

  const onSearch = (value) => {
    let url = `?limit=10&page=${page}`;
    const _params = { ...params };
    if (value.length === 0) {
      delete _params['filter[search]'];
      setParams(_params);
      navigate(url);
      return;
    }

    url = `${url}&filter[search]=${value}`;
    _params['filter[search]'] = value;
    setParams(_params);
    navigate(url);
  };

  // Effects
  useEffect(() => {
    fetchDevices();
  }, [page, params]);

  const columns = [
    { title: t('table.brand'), dataIndex: ['brand', 'name'], key: 'brand' },
    { title: t('table.name'), dataIndex: 'name', key: 'name' },
    { title: t('table.device'), dataIndex: 'model', key: 'model' },
    {
      title: t('table.actions'),
      key: 'actions',
      render: (item) => (
        <Space>
          <Button type="link" onClick={() => navigate(`/phone/${item.id}`)}>
            {t('common:view')}
          </Button>
          <Button
            type="link"
            onClick={() => navigate(`/edit-phone/${item.id}`)}
          >
            {t('common:edit')}
          </Button>
          {item.deletable && (
            <Button type="link" danger onClick={() => deleteDevice(item)}>
              {t('common:delete')}
            </Button>
          )}
        </Space>
      )
    }
  ];

  return (
    <Card
      title="Devices"
      extra={
        <Button onClick={() => navigate('/add-phone')} icon={<PlusOutlined />}>
          {t('phone.title')}
        </Button>
      }
    >
      {deletedDevice && (
        <Alert
          message={`${deletedDevice.brand.name} ${deletedDevice.name} ${deletedDevice.model} was deleted successfully`}
          description=""
          type="warning"
          showIcon
          closable
          onClose={() => setDeleteDevice()}
        />
      )}
      <Input.Search
        style={{ maxWidth: 400, paddingBottom: 15 }}
        allowClear
        placeholder={t('forms:placeHolder.searchDevice')}
        onSearch={onSearch}
        enterButton
      />
      {devices && (
        <Table
          dataSource={devices.rows}
          columns={columns}
          rowKey="id"
          pagination={{
            defaultCurrent: page,
            total: devices.total_data,
            defaultPageSize: 10,
            pageSize: 10,
            onChange: (page) => {
              setParams({
                limit: 10,
                page,
                include: 'brand:id,name',
                append: 'deletable',
                filter
              });
              navigate(`?page=${page}`);
            }
          }}
        />
      )}
    </Card>
  );
}

export default Phones;
