import BaseService from './base.service';

export class HeroImage extends BaseService {
  async getHeroImages() {
    const res = await this.axios.get('/hero-images');
    return res.data;
  }

  async createHeroImages(data) {
    const res = await this.axios.post('/hero-image', data);
    return res.data;
  }

  async activateOrDeactivate(heroImage) {
    const suffix = heroImage.is_active ? 'deactivate' : 'activate';
    const res = await this.axios.put(`/hero-image/${suffix}/${heroImage.id}`);
    return res.data;
  }

  async deleteHeroImages(heroId) {
    const res = await this.axios.delete(`/hero-image/${heroId}`);
    return res.data;
  }
}

const heroImageService = new HeroImage();
export default heroImageService;
