export const getStorage = (item) => {
  const storage = item.options.filter((i) => i.name === 'storage');

  if (storage === -1) {
    return;
  }

  let options = storage[0].options.data;

  const keys = [];
  const selectedOptions = [];

  options = options.map((i) => {
    const value = i.name.replace(' GB', '');

    keys.push(value);
    const name = `storage_${value}`;
    const newOption = { name, value, price: i.price };
    selectedOptions.push(newOption);
    return { [name]: i.price };
  });

  return { keys, options, selectedOptions };
};

export const getColor = (item) => {
  const color = item.options.filter((i) => i.name === 'colors');

  if (color === -1) {
    return;
  }

  const { options } = color[0];

  const keys = [];
  options.forEach((i) => {
    keys.push(i.toUpperCase());
  });

  return keys;
};

export const getQuestions = (item) => {
  let questions = item.charges;

  questions = questions.map((i) => ({
    [`question_${i.question_id}_answer_${i.answer_id}`]: i.charge,
  }));

  return questions;
};

export const getQuestionsPricing = (item) => {
  const { charges } = item;
  const { questions } = item.brand;

  const formattedQuestions = [];
  questions.forEach((i) => {
    const answers = charges.filter((item) => item.question_id === i.id);
    formattedQuestions.push({ ...i, answers });
  });

  return formattedQuestions;
};
