export const getAddressValue = (address) => `${address.address}, ${address.city}, ${address.zip}`;

export const filterObject = (column, operator, value) => ({
  column,
  operator,
  value
});

const setFilter = ({
  filter: { column, value, operator },
  formatedFilters,
  groupIndex,
  filterIndex
}) => {
  const prefix = `&filter_groups[${groupIndex}][filters][${filterIndex}]`;

  // Column
  formatedFilters += `${prefix}[column]=${column}`;

  // Operator
  formatedFilters += `${prefix}[operator]=${operator}`;

  // Value
  formatedFilters += `${prefix}[value]=${value}`;

  return formatedFilters;
};

export const formatFilters = (params) => {
  const filterGroups = params.filter_groups;
  let formatedFilters = '';
  let groupIndex = 0;

  if (filterGroups) {
    for (groupIndex; groupIndex < filterGroups?.length; groupIndex++) {
      const { filters } = filterGroups[groupIndex];

      let filterIndex = 0;
      if (!Array.isArray(filters)) {
        formatedFilters = setFilter({
          filter: filters,
          formatedFilters,
          groupIndex,
          filterIndex
        });

        // eslint-disable-next-line no-continue
        continue;
      }

      for (filterIndex; filterIndex < filters?.length; filterIndex++) {
        const filter = filters[filterIndex];
        if (filter && filter.value) {
          formatedFilters = setFilter({
            filter,
            formatedFilters,
            groupIndex,
            filterIndex
          });
        }
      }
    }

    delete params.filter_groups;
  }

  return { formatedFilters, updatedParams: params };
};

export default getAddressValue;
