import { useEffect, useState } from 'react';

import { Tag, Divider, PageHeader, Typography, List, Button } from 'antd';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useNavigate, useLocation } from 'react-router-dom';

// Services
import partnerService from 'services/partner.service';

function Partners() {
  // State
  const [partners, setPartners] = useState();

  // Hooks
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation('pages');

  // Declarations
  let page = new URLSearchParams(search).get('page');
  page = page || 1;

  // Handlers
  const fetchPartners = async () => {
    const params = `?page${page}`;

    const res = await partnerService.getPartners(params);
    if (res.partners) {
      setPartners(res.partners);
    }
  };

  const deletePartnerHandler = async (id) => {
    await partnerService.deletePartner(id);
    await fetchPartners();
  };

  // Effects
  useEffect(() => {
    fetchPartners();
  }, [page]);

  return (
    <>
      <PageHeader
        title={t('partner.title')}
        extra={[
          <Button
            key="add_category"
            icon={<PlusOutlined />}
            onClick={() => navigate('/add-partner')}
          >
            {t('partner.title')}
          </Button>
        ]}
      />
      <Divider />
      {partners && (
        <List
          size="default"
          style={{ textAlign: 'left' }}
          itemLayout="horizontal"
          dataSource={partners.rows}
          pagination={{
            defaultCurrent: 1,
            total: partners.total_data,
            defaultPageSize: 10,
            pageSize: 10,
            onChange: (page) => navigate(`?page=${page}`)
          }}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => navigate(`/edit-partner/${item.id}`)}
                >
                  <Typography.Text type="warning">
                    <EditOutlined /> {t('partner.edit')}
                  </Typography.Text>
                </Button>,
                <Button
                  type="link"
                  onClick={() => deletePartnerHandler(item.id)}
                >
                  <Typography.Text type="danger">
                    <DeleteOutlined /> {t('partner.delete')}
                  </Typography.Text>
                </Button>,
                <Button
                  type="link"
                  onClick={() => navigate(`/addresses/${item.id}`)}
                >
                  <Typography.Text>{t('common:address')}</Typography.Text>
                </Button>
              ]}
            >
              <List.Item.Meta
                title={<Tag color="volcano">{item.name}</Tag>}
                description={`${t('partner.positon')} ${t('partner.title')}`}
              />
            </List.Item>
          )}
        />
      )}
    </>
  );
}

export default Partners;
