import React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';

// Services
import submissionService from 'services/submission.service';

function Status({
  title,
  id,
  isOtherSubmission,
  onSubmit = () => null,
  statuses
}) {
  // Hooks
  const { t } = useTranslation('status');

  // Handlers
  const updateStatus = async (selectedStatus) => {
    await submissionService.updateStatusSubmission({
      id,
      isOtherSubmission,
      selectedStatus
    });
    onSubmit?.();
  };

  const handleOnChange = async (data) => {
    if (data) {
      await updateStatus(data);
    }
  };

  const statusesOption = statuses?.map((data) => ({
    label: t(data.name),
    value: data.name
  }));

  return (
    <div>
      <Select
        style={{ width: '100px' }}
        defaultValue={title}
        onChange={handleOnChange}
        options={statusesOption}
      />
    </div>
  );
}

export default Status;
