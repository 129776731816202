import BaseService from './base.service';

export class Reports extends BaseService {
  async getReports(url) {
    const res = await this.axios.get(`/reports${url}`);
    return res.data;
  }

  async createReport(data, allTime) {
    const formatData = {
      data: {
        all_time: allTime ? data.all_time : false,
        date_from: allTime ? null : data.date_from,
        date_to: allTime ? null : data.date_to,
        partner_id: data?.partner_id,
        type: data.type
      }
    };
    const res = await this.axios.post('/report', formatData);
    return res.data;
  }

  async downloadExecel() {
    const res = await this.axios.post('');
    return res;
  }
}

const reportsService = new Reports();
export default reportsService;
