import configuration from 'config';
import BaseService from './base.service';

export class Addresses extends BaseService {
  async getAddresses(id, params) {
    const res = await this.axios.get(`/addresses/partner/${id}`, { params });
    return res.data;
  }

  async deleteAddreses(id) {
    const res = await this.axios.delete(`/addresses/${id}`);
    return res.data;
  }

  async updateAddress(data) {
    const res = await this.axios.put('/partner/add-address', data);
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async searchByStreetName(value) {
    const params = {
      q: value,
      format: 'json',
      addressdetails: 1,
      polygon_geojson: 0
    };

    const _params = new URLSearchParams(params);
    const url = configuration().openStreetMapSearchUrl;
    const res = await fetch(`${url}${_params}`, {
      method: 'GET'
    });

    return res.json();
  }
}

const addressesService = new Addresses();
export default addressesService;
