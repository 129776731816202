import { Card, Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function DashboardCard({ title, data, link = '#', icon = null, today = false }) {
  const { t } = useTranslation('pages');

  return (
    <Card style={{ minWidth: '140px', textAlign: 'left', borderRadius: '16px', boxShadow: today ? 'inset 0 0 11px #ff713038' : '0 0 11px #00000017' }}>
      <h1>{icon} {title} </h1>
      <div className="">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {data}
        </div>
      </div>
      {link === '#' ? null : <Divider style={{ margin: '12px 0' }} />}
      <a href={link} style={{ textAlign: 'center', width: '100%', display: link === '#' ? 'none' : 'block' }}>{t('dashboard.viewAll')}</a>
    </Card>
  );
}

export default DashboardCard;
