import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Table, Divider, Space, Tag } from 'antd';

// Services
import submissionService from 'services/submission.service';

// Style
import './submission.css';

function Submission() {
  // State
  const [submission, setSubmission] = useState();
  const [questions, setQuestions] = useState();

  // Hooks
  const { id } = useParams();
  const { t } = useTranslation('pages');

  // Handlers
  const fetchSubmission = async () => {
    if (!id) {
      return;
    }

    const res = await submissionService.getSubmission(id);

    if (res.submissions) {
      setSubmission(res.submissions);

      const q = [];
      const data = res.submissions.device_condition;
      for (const key in data) {
        if (data[key].question) {
          q.push(data[key]);
        }
      }

      setQuestions(q);
    }
  };

  // Effects
  useEffect(() => {
    fetchSubmission();
  }, []);

  const columns = [
    { title: t('table.name'), dataIndex: 'name', key: 'name' },
    {
      title: t('table.street'),
      dataIndex: ['address', 'street'],
      key: 'street'
    },
    { title: t('table.zip'), dataIndex: ['address', 'zip'], key: 'zip' },
    { title: t('table.email'), dataIndex: 'email', key: 'email' },
    {
      title: t('table.phoneNumber'),
      dataIndex: 'phone_number',
      key: 'phone_number'
    },
    { title: t('table.note'), dataIndex: ['additional', 'note'], key: 'note' },
    {
      title: t('table.marketingInfo'),
      dataIndex: ['additional', 'marketing_information'],
      key: 'marketing_information'
    },
    {
      title: t('table.iban'),
      dataIndex: ['bank_information', 'iBan'],
      key: 'iBan'
    },
    {
      title: t('table.accountHolder'),
      dataIndex: ['bank_information', 'account_holder'],
      key: 'account_holder'
    }
  ];

  const phoneColumns = [
    {
      title: t('table.brand'),
      dataIndex: ['device', 'brand', 'name'],
      key: 'brand'
    },
    { title: t('table.name'), dataIndex: ['device', 'name'], key: 'name' },
    { title: 'Modell', dataIndex: ['device', 'model'], key: 'model' },
    {
      title: t('table.storage'),
      dataIndex: ['device_condition', 'storage', 'value'],
      key: 'storage'
    },
    {
      title: t('table.color'),
      dataIndex: ['device_condition', 'color'],
      key: 'color'
    },
    {
      title: t('table.price'),
      dataIndex: ['device_condition', 'price'],
      key: 'price',
      render: (price) => (price > 0 ? price : <Tag color="lime">{t('common:recycle')}</Tag>)
    }
  ];

  const quetionColumns = [
    { title: t('table.question'), dataIndex: 'question', key: 'question' },
    { title: t('table.answer'), dataIndex: 'answer', key: 'answer' }
  ];

  return (
    <div>
      {submission && (
        <Space direction="vertical">
          <Divider orientation="left">{t('submisson.personalInfo')}</Divider>
          <Table
            dataSource={[submission.personal_information]}
            columns={columns}
            rowKey="email"
            pagination={false}
          />

          <Divider orientation="left">{t('submisson.telefonDetails')}</Divider>
          <Table
            dataSource={[submission]}
            columns={phoneColumns}
            pagination={false}
          />

          <Divider orientation="left">{t('submisson.ask')}</Divider>
          <Table
            dataSource={questions}
            columns={quetionColumns}
            pagination={false}
          />
        </Space>
      )}
    </div>
  );
}

export default Submission;
