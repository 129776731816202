import { message } from 'antd';

import uploadService from 'services/upload.service';

// Configuration
import configuration from 'config/index';

export const fileUpload = async ({ method = 'POST', path, data }) => {
  await uploadService.uploadFile(method, path, data);
};

export const fakeUploader = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const handleImageChange = (info, setFileList) => {
  if (info.fileList.length === 0) {
    setFileList([]);
    return;
  }

  let fList = [info.fileList[info.fileList.length - 1]];

  fList = fList.map((file) => {
    if (file.response) {
      file.url = file.response.url;
    }
    return file;
  });
  setFileList(fList);
};

export const downloadFile = (suffix) => {
  window.location.href = `${configuration().baseUrl}${suffix}`;
};
