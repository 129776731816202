import { createSlice } from '@reduxjs/toolkit';

const layout = createSlice({
  name: 'layout',
  initialState: {
    loading: false,
  },
  reducers: {
    initLoading: (state) => {
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
  },
});

export const { initLoading, finishLoading } = layout.actions;
export default layout.reducer;
