import { useEffect, useRef, useState } from 'react';

import { Form, Input, Button, Select, Divider } from 'antd';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents
} from 'react-leaflet';
import { Icon } from 'leaflet';

import { useTranslation } from 'react-i18next';

import { debounce } from 'lodash';

// Utils
import notificationPopUp from 'utils/notificationPopUp';

// Services
import addressesService from 'services/addresses.service';

// Hooks
import useGeoLocation from 'hooks/useGeoLocation';

// Components
import marker from 'assets/icons/marker-icon.png';

const markerIcon = new Icon({
  iconUrl: marker,
  iconSize: [20, 32]
});

const sytleOfMap = {
  height: '300px',
  width: '100%'
};

function LocationMarker({ setSelectedPosition }) {
  const { t } = useTranslation('address');
  const [position, setPosition] = useState();

  useEffect(() => {
    if (position) {
      setSelectedPosition(position);
    }
  }, [position]);

  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    }
  });

  return position ? (
    <Marker position={position} icon={markerIcon}>
      <Popup>{t('addAddress.youAreHere')}</Popup>
    </Marker>
  ) : null;
}

function AddAddress({ addressForm, submitAddressData }) {
  // State
  const [selectedPosition, setSelectedPosition] = useState();
  const [listPlace, setListPlace] = useState();
  const [loading, setLoading] = useState();

  // Hooks
  const { t } = useTranslation('forms');
  const location = useGeoLocation();
  const mapRef = useRef(null);

  // Handlers
  const constructAddressData = (values) => {
    const data = {
      ...values,
      lat: selectedPosition.lat,
      long: selectedPosition.lng
    };
    data.is_partner = true;
    submitAddressData(data);
  };

  const handleFilterByStreetName = async (value) => {
    if (!value) return;
    setLoading(true);
    try {
      const res = await addressesService.searchByStreetName(value);
      setListPlace(res);
    } catch (error) {
      notificationPopUp(error);
    }
    setLoading(false);
  };

  const handleAddressClicked = (value) => {
    const clickedAddress = listPlace?.find((place) => place.place_id === value);

    if (clickedAddress) {
      mapRef.current?.flyTo?.([clickedAddress.lat, clickedAddress.lon], 18);
    }
  };

  const debounceInput = debounce(handleFilterByStreetName, 800);

  const addressesOptions = listPlace?.map((item) => ({
    value: item.place_id,
    label: item.display_name
  }));

  const handleSearch = async (value) => debounceInput(value);

  return (
    <Form form={addressForm} onFinish={constructAddressData} layout="vertical">
      <Form.Item
        name="address"
        label={t('label.address')}
        rules={[
          { required: true, message: t('requiredMessage.address').toString() }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="city"
        label={t('label.city')}
        rules={[{ required: true, message: t('requiredMessage.city') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="zip"
        label={t('label.zip')}
        rules={[{ required: true, message: t('requiredMessage.zip') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone_number_1"
        label={t('label.phoneNumber')}
        rules={[{ required: true, message: t('requiredMessage.phone') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="phone_number_2" label={`${t('label.phoneNumber')} 2`}>
        <Input />
      </Form.Item>

      {location.coordinates && location.coordinates.lat ? (
        <div>
          <Divider orientation="left">
            {' '}
            {t('address:addAddress.location')}{' '}
          </Divider>
          <Select
            onSearch={handleSearch}
            showSearch
            loading={loading}
            size="large"
            filterOption={false}
            style={{ width: '100%' }}
            defaultOpen={false}
            placeholder={t('placeHolder.searchByStreetNameCity')}
            onChange={handleAddressClicked}
            options={addressesOptions || []}
            notFoundContent={t('address:addAddress.noOptionAvalilable')}
            autoComplete="none"
          />

          <MapContainer
            center={[location.coordinates.lat, location.coordinates.lng]}
            zoom={13}
            style={sytleOfMap}
            whenCreated={(mapInstance) => {
              mapRef.current = mapInstance;
            }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            />
            <LocationMarker
              setSelectedPosition={(data) => setSelectedPosition(data)}
            />
          </MapContainer>
        </div>
      ) : (
        <p>{t('address:addAddress.loacationPermissions')}</p>
      )}
      <Button
        htmlType="submit"
        type="primary"
        disabled={!selectedPosition}
        style={{ marginTop: '10px' }}
      >
        {t('address:addAddress.submitAddress')}
      </Button>
    </Form>
  );
}

export default AddAddress;
