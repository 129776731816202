import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Space, Button, Table, Popconfirm, Tag, Card } from 'antd';

import { useTranslation } from 'react-i18next';

// Hooks
import useQueryHook from 'hooks/useQueryHook';

// Utils
import { formatDateWithHour } from 'utils/data.utils';

// Services
import submissionService from 'services/submission.service';

// Containers
import FilterForm from 'containers/FilterForm';
import CreateDevice from 'containers/CreateDevice';

// Config
import config from 'config';

// Components
import Status from 'containers/Status';

function OtherSumbissions() {
  // State
  const [createDeviceModal, setCreateDeviceModal] = useState(false);
  const [otherSubmissions, setOtherSubmissions] = useState();
  const [statuses, setStatuses] = useState([]);

  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation('pages');
  const {
    updateParams,
    params,
    dateFrom,
    dateTo,
    searchValue,
    statusValue,
    _page
  } = useQueryHook();

  // Handlers
  const handleGoToPage = (page) => {
    const url = `?tab=other-submissions&page=${page}${
      searchValue ? `&search=${searchValue}` : ''
    }${statusValue ? `&status=${statusValue}` : ''}${
      dateFrom ? `&dateFrom=${dateFrom}` : ''
    }${dateTo ? `&dateTo=${dateTo}` : ''}`;
    updateParams({ params: { ...params, page }, url });
  };
  const fetchOtherSubmissions = async (_param) => {
    if (!_param) {
      return;
    }
    const res = await submissionService.getOtherSubmissions({
      ..._param
    });

    if (res.submissions.total_data / 10 < 1 && params.page > 1) {
      return handleGoToPage(1);
    }
    setOtherSubmissions(res.submissions);
  };

  const handleDelete = async (id) => {
    const res = await submissionService.deleteOtherSubmission(id);
    if (res) fetchOtherSubmissions({ ...params });
  };

  const handleSort = async (direction, field) => {
    const sortDirection = direction === 'ascend' ? 'sortByAsc' : 'sortByDesc';

    const _params = {
      ...params
    };

    delete _params.sortByAsc;
    delete _params.sortByDesc;

    _params[sortDirection] = field.join('->');

    updateParams({ params: _params }, false);
  };

  const onChange = async (pagination, filters, sorter) => {
    if (Object.prototype.hasOwnProperty.call(sorter, 'column')) {
      await handleSort(sorter.order, sorter.field);
    }
  };

  const fetchStatuses = async () => {
    const res = await submissionService.getStatuses();
    if (res.statuses) {
      setStatuses(res.statuses.rows);
    }
  };

  const downloadPdf = (id, access_key) => {
    window.location.href = `${
      config().baseUrl
    }/view-other-submission-pdf/${id}?access_key=${access_key}`;
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  // Effects
  useEffect(() => {
    fetchOtherSubmissions(params);
  }, [params, _page]);

  const columns = [
    {
      title: t('table.barcode'),
      key: 'code',
      dataIndex: 'code',
      render: (code) => <Tag>{code}</Tag>
    },
    {
      title: t('table.name'),
      dataIndex: ['personal_information', 'name'],
      key: 'name',
      sorter: () => true
    },
    {
      title: t('table.street'),
      dataIndex: ['personal_information', 'address', 'street'],
      key: 'street'
    },
    {
      title: t('table.zip'),
      dataIndex: ['personal_information', 'address', 'zip'],
      key: 'zip'
    },
    {
      title: t('table.status'),
      key: 'status_name',
      render: (data) => (
        <Status
          onSubmit={() => null}
          isOtherSubmission
          title={data.status_name}
          id={data.id}
          statuses={statuses}
        />
      )
    },
    {
      title: 'Sold at',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (createdAt) => (
        <span style={{ width: '120px', display: 'flex' }}>
          {formatDateWithHour(createdAt)}
        </span>
      )
    },
    {
      title: 'Aktionen',
      key: 'actions',
      render: (data) => (
        <Space>
          <Button
            type="link"
            onClick={() => navigate(`/other-submission/${data.id}`)}
          >
            {t('common:view')}
          </Button>

          <Button
            type="link"
            onClick={() => downloadPdf(data.id, data.access_key.key)}
          >
            {`PDF ${t('common:download')}`}
          </Button>
          <Popconfirm
            placement="topLeft"
            title={`${t('common:delete')} ?`}
            onConfirm={() => handleDelete(data.id)}
            okText={t('common:yes')}
            cancelText={t('common:no')}
          >
            <Button type="link" danger>
              {t('common:delete')}
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <Card bordered={false}>
      <FilterForm
        setCreateDeviceModal={setCreateDeviceModal}
        updateParams={updateParams}
        otherSubmission
      />

      <CreateDevice
        visible={createDeviceModal}
        isOtherSubmission
        onClose={() => setCreateDeviceModal(false)}
      />

      <div>
        {otherSubmissions && (
          <Table
            dataSource={otherSubmissions.rows}
            rowKey="id"
            onChange={onChange}
            columns={columns}
            sortDirections={['descend', 'ascend', 'descend']}
            pagination={{
              defaultCurrent: _page || 1,
              total: otherSubmissions.total_data,
              defaultPageSize: 10,
              pageSize: 10,
              onChange: (page) => handleGoToPage(page)
            }}
          />
        )}
      </div>
    </Card>
  );
}

export default OtherSumbissions;
