import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Spin } from 'antd';

import { finishLoading, initLoading } from 'store/layout';
import { configureAPI } from 'utils/api';
import { logout } from 'store/user';

import { privateRoutes, publicRoutes } from './AppRoutes';

function Authorization() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  if (user.loading) {
    return (
      <BrowserRouter>
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
          <Spin />
        </div>
      </BrowserRouter>
    );
  }

  // Configure access token
  configureAPI({
    token: user?.data?.access_token,
    onSignOut: () => dispatch(logout),
    initLoading: () => dispatch(initLoading()),
    finishLoading: () => dispatch(finishLoading())
  });

  return (
    <BrowserRouter>
      {user.data.access_token ? privateRoutes : publicRoutes}
    </BrowserRouter>
  );
}

export default Authorization;
