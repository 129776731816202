import jwt from 'jsonwebtoken';

export const verifyToken = (data) => {
  if (!data || !data.access_token) {
    return false;
  }

  const token = jwt.decode(data.access_token);

  const now = Date.now();

  if (token) {
    if (now > `${parseInt(token.exp)}000`) {
      return false;
    }
    return data;
  }

  return false;
};

export const storeUser = (data) => {
  localStorage.setItem('userData', JSON.stringify(data));
};

export const removeUser = () => {
  localStorage.removeItem('userData');
};

export const isLogin = (storedUser) => {
  try {
    const userData = JSON.parse(localStorage.getItem(storedUser));

    if (!userData) return false;

    const accessToken = userData.access_token;

    const token = accessToken ? jwt.decode(accessToken) : null;

    const now = Date.now();
    if (token) {
      if (now > `${parseInt(token.exp)}000`) {
        removeUser();
        return false;
      }
      return userData;
    }

    localStorage.removeItem('userData');
    return false;
  } catch (error) {
    return false;
  }
};

export const verifyLogout = (storedUser) => {
  try {
    const userData = JSON.parse(localStorage.getItem(storedUser));

    if (!userData) {
      localStorage.removeItem('userData');
      return false;
    }

    const headers = {};

    const accessToken = userData.access_token;

    const tokenData = accessToken ? jwt.decode(accessToken) : null;

    if (tokenData) {
      tokenData.access_token = accessToken;
      headers.Authorization = `Bearer ${accessToken}`;
    }

    localStorage.removeItem('userData');

    return headers;
  } catch (error) {
    return false;
  }
};
