const config = {
  appEnv: process.env.REACT_APP_ENV,
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
  allowedRoles: process.env.REACT_APP_ALLOWED_ROLES || '',
  openStreetMapSearchUrl: process.env.REACT_APP_OPEN_STREET_MAP_SEARCH_URL,
  appLanguage: (process.env.REACT_APP_LANGUAGE || 'en').toLowerCase().toString()
};

const configuration = () => {
  if (!config.appEnv) throw new Error('App env variable is missing!');
  if (!config.baseUrl) throw new Error('Base url is missing!');
  if (!config.allowedRoles) throw new Error('Allowed roles are missing!');
  if (!config.openStreetMapSearchUrl) {
    throw new Error('Open Street Map Search Url is missing.');
  }

  return config;
};

export default configuration;
