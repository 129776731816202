import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Table, Tag, PageHeader, Button } from 'antd';

// Utils
import { downloadFile } from 'utils/file.util';
import GenerateReport from 'containers/GenerateReport';
import { formatDate } from 'utils/data.utils';

// Services
import reportsService from 'services/reports.service';
import { useLocation, useNavigate } from 'react-router-dom';

function Reports() {
  // State
  const [reports, setReports] = useState();
  const [visible, setVisible] = useState();

  // Pagination
  const navigate = useNavigate();
  const { search } = useLocation();
  const page = +new URLSearchParams(search).get('page') || 1;

  // Hooks
  const user = useSelector((state) => state.user.data);
  const { t } = useTranslation('pages');

  const reportDate = (item, key, subKey) => {
    if (item[key]) {
      return formatDate(item[key]);
    }

    if (item[subKey]) {
      return '-';
    }

    return 'N/A';
  };

  const columns = [
    {
      title: t('table.code'),
      dataIndex: 'code',
      key: 'code',
      render: (text) => <Tag>{text}</Tag>
    },
    {
      title: t('table.dateFrom'),
      key: 'date_form',
      render: (item) => reportDate(item, 'date_from', 'date_to')
    },
    {
      title: t('table.dateTo'),
      key: 'date_to',
      render: (item) => reportDate(item, 'date_to', 'date_from')
    },
    {
      title: t('table.generateOn'),
      dataIndex: ['created_at'],
      key: 'created_at',
      render: (date) => (date ? formatDate(date) : 'N/A')
    },
    {
      title: t('table.document'),
      dataIndex: ['id'],
      key: 'id',
      render: (id) => (
        <Button
          onClick={() => downloadFile(`/report/${id}?access_token=${user.access_token}`)}
          type="primary"
        >
          {`${t('common:download')} Excel`}
        </Button>
      )
    }
  ];

  // Handlers
  const fetchReports = async (page = 1) => {
    const res = await reportsService.getReports(`?page=${page}`);
    setReports(res.reports);
  };

  const handleCloseModal = async () => {
    setVisible(false);
    await fetchReports();
  };

  // Effects
  useEffect(() => {
    fetchReports(page);
  }, [page]);

  return (
    <>
      <GenerateReport visible={visible} onClose={handleCloseModal} />
      <PageHeader
        ghost={false}
        title="Reports"
        extra={[
          <Button type="primary" onClick={() => setVisible(true)}>
            {`+ ${t('report.title')}`}
          </Button>
        ]}
      />
      <Table
        columns={columns}
        dataSource={reports?.data}
        pagination={{
          defaultCurrent: page,
          total: reports?.total,
          defaultPageSize: 10,
          onChange: (_page) => navigate(`?page=${_page}`)
        }}
      />
    </>
  );
}

export default Reports;
