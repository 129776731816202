import BaseService from './base.service';

export class Device extends BaseService {
  async getDevice(id) {
    if (!id) return;

    const params = {
      include: 'brand;options;charges;charges.answer;image'
    };

    const res = await this.axios.get(`/devices/${id}`, { params });
    return res.data;
  }

  async getDevices(params) {
    const res = await this.axios.get('/devices', { params });
    return res.data.devices;
  }

  async createDevice(isOtherSubmission, data) {
    const url = isOtherSubmission
      ? '/admin/other-submission'
      : '/admin/submission';

    const res = await this.axios.post(url, { submission: data });
    return res.data;
  }

  async storeDevice(device) {
    const res = await this.axios.post('', { data: device });
    return res.data.device;
  }

  async deleteDevice(id) {
    const res = await this.axios.delete(`/devices/${id}`);
    return res;
  }

  async editablePrice({ submissionId, price }) {
    const res = await this.axios.put(
      `/submission/change-price/${submissionId}`,
      { price }
    );
    return res.data;
  }
}

const deviceService = new Device();
export default deviceService;
