import { useEffect, useState } from 'react';
import { Card, Divider } from 'antd';
import { FileTextFilled, FileZipFilled, PhoneFilled, SnippetsOutlined, UsergroupAddOutlined, ApartmentOutlined } from '@ant-design/icons';

import DashboardCard from 'components/DashboardCard';
import DashboardCardBlock from 'components/DashboardCardBlock';
import DashboardCardWrapper from 'components/DashboardCardWrapper';
import notificationPopUp from 'utils/notificationPopUp';
import submissionService from 'services/submission.service';
import configuration from 'config';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({});

  const { t } = useTranslation('pages');

  const handleGetDashboard = async () => {
    try {
      const data = await submissionService.getDashboard();
      setDashboardData(data.dashboard);
    } catch (err) {
      notificationPopUp({ message: err.message });
    }
  };

  useEffect(() => {
    handleGetDashboard();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
        <DashboardCardBlock>
          <DashboardCardWrapper>
            <DashboardCard data={dashboardData.totalDevices} title={t('dashboard.devices')} link="/phones" icon={<PhoneFilled />} />
            <DashboardCard data={dashboardData.totalSubmissions + dashboardData.totalOtherSubmissions} title={t('dashboard.submissions')} link="/submissions" icon={<FileZipFilled />} />
            <DashboardCard data={dashboardData.totalPartners} title={t('dashboard.partners')} link="/partners" icon={<ApartmentOutlined />} />
            <DashboardCard data={dashboardData.totalUsers} title={t('dashboard.users')} link="/users" icon={<UsergroupAddOutlined />} />
            <DashboardCard data={dashboardData.totalReports} title={t('dashboard.reports')} link="/reports" icon={<SnippetsOutlined />} />
          </DashboardCardWrapper>
        </DashboardCardBlock>
        <DashboardCardBlock>
          <h1><FileZipFilled />  {t('dashboard.submissions')} </h1>
          <DashboardCardWrapper>
            <DashboardCard today title={t('dashboard.today')} data={dashboardData.todaySubmissions} />
            <DashboardCard title={t('dashboard.yesterday')} data={dashboardData.yesterdaySubmissions} />
            <DashboardCard title={t('dashboard.lastWeek')} data={dashboardData.lastWeekSubmissions} />
            <DashboardCard title={t('dashboard.lastMonth')} data={dashboardData.lastMonthSubmissions} />
            <DashboardCard title={t('dashboard.total')} data={dashboardData.totalSubmissions} />
            <Divider type="vertical" />
            <DashboardCard title={t('dashboard.reports')} icon={<SnippetsOutlined />} data={dashboardData.submissionReports} />
          </DashboardCardWrapper>
        </DashboardCardBlock>
        <DashboardCardBlock>
          <h1><FileTextFilled /> {t('dashboard.otherSubmissions')}</h1>
          <DashboardCardWrapper>
            <DashboardCard today title={t('dashboard.today')} data={dashboardData.todayOtherSubmissions} />
            <DashboardCard title={t('dashboard.yesterday')} data={dashboardData.yesterdayOtherSubmissions} />
            <DashboardCard title={t('dashboard.lastWeek')} data={dashboardData.lastWeekOtherSubmissions} />
            <DashboardCard title={t('dashboard.lastMonth')} data={dashboardData.lastMonthOtherSubmissions} />
            <DashboardCard title={t('dashboard.total')} data={dashboardData.totalOtherSubmissions} />
            <Divider type="vertical" />
            <DashboardCard title={t('dashboard.reports')} icon={<SnippetsOutlined />} data={dashboardData.otherSubmissionReports} />
          </DashboardCardWrapper>
        </DashboardCardBlock>
        <DashboardCardBlock>
          <h1><FileTextFilled /> {t('dashboard.mostSold')}</h1>
          <DashboardCardWrapper>
            {dashboardData && dashboardData.popularDevices?.map((item) => (
              // eslint-disable-next-line prefer-template
              <Link to={'/phone/' + item.id} key={item.id}>
                <Card style={{ minWidth: '140px', textAlign: 'left', borderRadius: '16px', boxShadow: '0 0 11px #00000017' }}>
                  <div className="">
                    <div style={{ maxWidth: '150px', height: '150px', display: 'flex', flexDirection: 'row' }}>
                      <img alt={item.id} width="100%" src={configuration().baseUrl + item.image_path} />
                    </div>
                    <Divider />
                    <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                      <span style={{ fontWeight: 'bold' }}>{item.brand_name}</span>
                      <span>{item.name} </span>
                      <span> {item.model}</span>
                    </div>
                  </div>
                </Card>
              </Link>
            ))}
          </DashboardCardWrapper>
        </DashboardCardBlock>
      </div>
      <Divider />
    </div>
  );
}

export default Dashboard;
