export const statusOptions = (t) => [
  {
    value: 'all',
    label: t('status:all')
  },
  {
    value: 'sold',
    label: t('status:sold')
  },
  {
    value: 'in_process',
    label: t('status:in_process')
  },
  {
    value: 'completed',
    label: t('status:completed')
  },
  {
    value: 'cancelled',
    label: t('status:cancelled')
  }
];

export default statusOptions;
