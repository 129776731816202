import { Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';

function Loader() {
  const { loading } = useSelector((state) => state.layout);

  return (
    <div>
      {loading && (
        <Modal
          width="300px"
          visible={loading}
          footer={null}
          closable={false}
          centered
          style={{ textAlign: 'center' }}
        >
          <Spin tip="Loading..." size="large" />
        </Modal>
      )}
    </div>
  );
}

export default Loader;
