import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Upload,
  Avatar,
  Select,
  Tag
} from 'antd';
import Modal from 'antd/lib/modal/Modal';

// Utils
import {
  handleImageChange,
  fakeUploader,
  beforeUpload,
  fileUpload
} from 'utils/file.util';
import { formatDateWithHour } from 'utils/data.utils';

// Services
import heroImageService from 'services/heroImages.service';
import configuration from 'config';

function HeroImages() {
  // State
  const [openModal, setOpenModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [heroImages, setHeroImages] = useState();

  // Pagination
  const { search } = useLocation();
  const page = +new URLSearchParams(search).get('page') || 1;

  // Hooks
  const submittButtonRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation('pages');
  const [form] = Form.useForm();

  // Handlers

  const handleShowModal = () => {
    setOpenModal(!openModal);
    form.resetFields();
    setFileList([]);
  };

  const fetchHeroImages = async () => {
    const res = await heroImageService.getHeroImages();

    if (res.images) {
      setHeroImages(res.images);
    }
  };

  const handleSetHeroImage = async (heroImage) => {
    await heroImageService.activateOrDeactivate(heroImage);
    await fetchHeroImages();
  };

  const uploadHeroImage = async (values) => {
    const fileData = new FormData();

    fileData.append('image', values.image.file.originFileObj);
    fileData.append('language', values.lang);

    await fileUpload({
      path: '/hero-image',
      data: fileData
    });
    await fetchHeroImages();
    handleShowModal();
  };

  const handleDeleteHeroImage = async (heroId) => {
    await heroImageService.deleteHeroImages(heroId);
    await fetchHeroImages();
  };

  // Table columns
  const columns = [
    {
      title: t('table.image'),
      key: 'path',
      render: (data) => (
        <Avatar
          size="large"
          shape="square"
          src={`${configuration().baseUrl}${data.path}`}
        />
      )
    },
    {
      title: t('heroImages.language'),
      key: 'language',
      render: (data) => (
        <Tag color={data.language === 'de' ? 'volcano' : 'blue'}>
          {data.language.toUpperCase()}
        </Tag>
      )
    },
    {
      title: t('table.active'),
      key: 'is_active',
      sorter: () => true,
      render: (data) => (
        <Button
          onClick={() => handleSetHeroImage(data)}
          danger={data.is_active}
          type="primary"
        >
          {data.is_active ? t('heroImages.active') : t('heroImages.notActive')}
        </Button>
      )
    },
    {
      title: t('table.uploadedAt'),
      dataIndex: 'created_at',
      render: (data) => <p>{formatDateWithHour(data)}</p>
    },

    {
      title: t('table.actions'),
      key: 'actions',
      render: (data) => (
        <Space>
          <Popconfirm
            placement="bottomRight"
            title={`${t('common:delete')} ?`}
            onConfirm={() => handleDeleteHeroImage(data.id)}
            okText={t('common:yes')}
            cancelText={t('common:no')}
          >
            <Button type="link" danger>
              {t('common:delete')}
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  // Effects
  useEffect(() => {
    fetchHeroImages();
  }, []);

  // JSX
  return (
    <div>
      <Modal
        visible={openModal}
        title={t('heroImages.addImage')}
        onCancel={handleShowModal}
        onOk={() => submittButtonRef?.current?.click()}
      >
        <Form
          initialValues={{ lang: 'de' }}
          form={form}
          onFinish={uploadHeroImage}
          layout="vertical"
        >
          <Form.Item name="lang" label={t('heroImages.selectLanguage')}>
            <Select
              options={[
                { value: 'en', label: 'English' },
                { value: 'de', label: 'German' }
              ]}
            />
          </Form.Item>

          <Form.Item
            style={{ textAlign: 'center' }}
            name="image"
            rules={[
              {
                required: fileList.length === 0,
                message: t('forms:requiredMessage.image')
              }
            ]}
          >
            <Upload
              name="image"
              listType="picture"
              customRequest={fakeUploader}
              showUploadList
              onChange={(info) => handleImageChange(info, setFileList)}
              beforeUpload={beforeUpload}
              fileList={fileList}
            >
              <Button type="primary" danger>
                {t('heroImages.upload')}
              </Button>
            </Upload>
          </Form.Item>

          <Button htmlType="submit" hidden ref={submittButtonRef}>
            {t('common:submit')}
          </Button>
        </Form>
      </Modal>

      <PageHeader
        title={t('heroImages.title')}
        extra={[
          <Button
            key="add_hero"
            icon={<PlusOutlined />}
            onClick={handleShowModal}
          >
            {t('heroImages.hero')}
          </Button>
        ]}
      />

      {heroImages && (
        <Table
          dataSource={heroImages.rows}
          rowKey="id"
          columns={columns}
          sortDirections={['descend', 'ascend', 'descend']}
          pagination={{
            defaultCurrent: page,
            total: heroImages.total_data,
            defaultPageSize: 10,
            pageSize: 10,
            onChange: (page) => navigate(`?page=${page}`)
          }}
        />
      )}
    </div>
  );
}

export default HeroImages;
