import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Alert,
  Modal,
  List,
  Space,
  Upload
} from 'antd';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// Services
import userService from 'services/user.service';
import partnerService from 'services/partner.service';

// Config
import config from 'config';

// Utils
import {
  fileUpload,
  fakeUploader,
  beforeUpload,
  handleImageChange
} from 'utils/file.util';

// Components
import AddAddress from 'containers/AddAddress';

function PartnerForm() {
  // State
  const [addedPartner, setAddedPartner] = useState();
  const [addresses, setAddresses] = useState([]);
  const [addAddress, setAddAddress] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [partner, setPartner] = useState();
  const [fileList, setFileList] = useState([]);

  // Hooks
  const { t } = useTranslation('pages');
  const { id } = useParams();
  const [form] = Form.useForm();
  const [addressForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();

  // Handlers
  const fetchPartner = async () => {
    if (!id) {
      return;
    }

    const res = await partnerService.getPartner(id);

    const data = res.partner;

    setPartner({
      ...data,
      user_name: data.owner?.name,
      user_email: data.owner?.email,
      iban: data.payment_method?.value
    });

    if (data.image && data.image.path) {
      setFileList([
        {
          uid: '-1',
          name: data.name,
          status: 'done',
          url: `${config().baseUrl}${data.image.path}`
        }
      ]);
    }

    const newAddresses = data.addresses.map((address) => ({
      ...address,
      notDeletable: true
    }));
    setAddresses(newAddresses);
    form.resetFields();
  };

  const updatePartner = async (data, values) => {
    const res = await partnerService.updatePartner(id, data);
    if (fileList.length) {
      const fileData = new FormData();
      fileData.append('image', values.image.file.originFileObj);
      await fileUpload({
        path: `/partner/image-upload/${res.partner.id}`,
        data: fileData
      });
    }
  };

  const createPartner = async (data, values) => {
    const res = await partnerService.createPartner(data);
    setAddedPartner(res.user);
    if (fileList.length) {
      const fileData = new FormData();
      fileData.append('image', values.image.file.originFileObj);
      await fileUpload({
        path: `/partner/image-upload/${res.partner.id}`,
        data: fileData
      });
    }
    setFileList([]);
  };

  const submitPartnerData = async (values) => {
    const data = {
      name: values.name,
      user: {
        name: values.user_name
      },
      payment_method: {
        key: 'iban',
        value: values.iban
      },
      addresses,
      link: values.link
    };

    if (id) {
      updatePartner(data, values);
      form.resetFields();
      setAddresses([]);

      return;
    }

    data.user.email = values.user_email;
    createPartner(data, values);

    form.resetFields();
    setAddresses([]);
  };

  const submitAddressData = (values) => {
    const newAddresses = [...addresses];
    newAddresses.push(values);
    setAddresses([...newAddresses]);
    setAddAddress(false);
    addressForm.resetFields();
  };

  const removeAddressHandler = (index) => {
    const newAddresses = [...addresses];
    newAddresses.splice(index, 1);
    setAddresses([...newAddresses]);
  };

  const changePassword = async (values) => {
    const data = {
      user: {
        old_password: values.old_password,
        password: values.new_password,
        password_confirmation: values.confirm_password
      }
    };

    await userService.changePassword(id, data);
    form.resetFields();
  };

  const resetPasswordHandler = async () => {
    const res = await userService.resetPassword(id);
    if (res) {
      setAddedPartner(res.user);
      setPasswordReset(true);
    }
  };

  // Effects
  useEffect(() => {
    fetchPartner();
  }, []);

  return (
    <Card
      title={id ? t('patner.editPartner') : t('partner.createPartner')}
      bordered={false}
      extra={
        <Space>
          {!id && (
            <Button onClick={() => setAddAddress(true)} type="primary">
              {t('address:addAddress.submitAddress')}
            </Button>
          )}
          {partner && (
            <Button onClick={resetPasswordHandler}>
              {t('partner.resetPassword')}
            </Button>
          )}
        </Space>
      }
    >
      {addedPartner && (
        <Alert
          message={
            passwordReset
              ? t('partner.passwordResetSuccess')
              : t('partner.partnerCreated')
          }
          description={
            <>
              <p>
                {t('forms:label.email')}:{addedPartner.email}
              </p>
              <p>
                {t('forms:label.password')}:{addedPartner.generated_password}
              </p>
            </>
          }
          type="success"
          showIcon
        />
      )}
      <Modal
        visible={addAddress}
        footer={null}
        onCancel={() => setAddAddress(false)}
      >
        <AddAddress
          submitAddressData={submitAddressData}
          addressForm={addressForm}
        />
      </Modal>
      <Form
        form={form}
        initialValues={{ ...partner }}
        onFinish={submitPartnerData}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label={t('forms:label.companyName')}
          rules={[
            { required: true, message: t('forms:requiredMessage.companyName') }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="user_name"
          label={t('forms:label.ownerName')}
          rules={[
            { required: true, message: t('forms:requiredMessage.ownerName') }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="user_email"
          label={t('forms:label.email')}
          rules={[
            { required: true, message: t('froms:requiredMessage.email') }
          ]}
        >
          <Input type="email" disabled={id} />
        </Form.Item>
        <Form.Item
          name="iban"
          label={t('forms:label.iban')}
          rules={[{ required: true, message: t('forms:requiredMessage.iban') }]}
          style={{ position: 'relative' }}
        >
          <Input
            maxLength="23"
            pattern=".{23}"
            placeholder="CH123456789"
            title={t('forms:info.ibanInfo')}
          />
        </Form.Item>
        <Form.Item name="link" label={t('common:website')}>
          <Input />
        </Form.Item>
        <Form.Item
          name="image"
          rules={[
            {
              required: fileList.length === 0,
              message: t('forms:requiredMessage.image')
            }
          ]}
        >
          <Upload
            name="image"
            listType="picture"
            customRequest={fakeUploader}
            showUploadList
            onChange={(info) => handleImageChange(info, setFileList)}
            beforeUpload={beforeUpload}
            fileList={fileList}
          >
            <Button>{t('heroImages.upload')}</Button>
          </Upload>
        </Form.Item>

        {addresses.length > 0 && (
          <List
            itemLayout="horizontal"
            dataSource={addresses}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <Button
                    danger
                    type="link"
                    onClick={() => removeAddressHandler(index)}
                    disabled={item.notDeletable}
                  >
                    {t('common:remove')}
                  </Button>
                ]}
              >
                <List.Item.Meta
                  title={item.address}
                  description={`${item.city}, ${item.zip}`}
                />
              </List.Item>
            )}
          />
        )}

        <div style={{ paddingTop: '20px' }}>
          <Button
            htmlType="submit"
            style={{ float: 'right' }}
            disabled={addresses.length === 0}
          >
            {t('common:submit')}
          </Button>
        </div>
      </Form>

      {partner && (
        <>
          <Divider />
          <Form
            form={changePasswordForm}
            onFinish={changePassword}
            layout="vertical"
          >
            <Form.Item
              name="old_password"
              label={t('partner.oldPassword')}
              rules={[
                {
                  required: true,
                  message: t('forms:requiredMessage.oldPassword')
                }
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="new_password"
              label={t('partner.newPassword')}
              rules={[
                {
                  required: true,
                  message: t('forms:requiredMessage.newPassword')
                }
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label={t('forms:label.confirmPassword')}
              rules={[
                {
                  required: true,
                  message: t('forms:requiredMessage.confirmPassword')
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t('forms:requiredMessage.notSameAsNewPassword')
                    );
                  }
                })
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Button htmlType="submit" style={{ float: 'right' }}>
              {t('forms:label.changePassword')}
            </Button>
          </Form>
        </>
      )}
    </Card>
  );
}

export default PartnerForm;
