import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Button, Card, Divider, Form, Input, Select, Alert } from 'antd';

// Services
import userService from 'services/user.service';
import settingsSerivce from 'services/settings.service';

function Settings() {
  // State
  const [updated, setUpdated] = useState(false);

  // Hooks
  const authedUser = useSelector((state) => state.user.data);
  const [form] = Form.useForm();
  const { t } = useTranslation('pages');
  const [changePasswordForm] = Form.useForm();

  // Handlers
  const submitUserData = async (values) => {
    const data = {
      user: {
        name: values.name,
        role_name: values.role_name
      }
    };

    await settingsSerivce.updateUser(authedUser.id, data);
    setUpdated(true);
    form.resetFields();
  };

  const changePassword = async (values) => {
    const data = {
      user: {
        old_password: values.old_password,
        password: values.new_password,
        password_confirmation: values.confirm_password
      }
    };

    await userService.changePassword(authedUser.id, data);
    setUpdated(true);
    form.resetFields();
  };

  // Effects
  useEffect(() => {
    form.resetFields();
  }, [authedUser]);

  return (
    <Card title={t('settings.title')} bordered={false}>
      {updated && (
        <Alert
          message={t('settings.changesSaved')}
          description=""
          type="success"
          showIcon
        />
      )}
      <Form
        form={form}
        initialValues={{ ...authedUser }}
        onFinish={submitUserData}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label={t('forms:label.name')}
          rules={[{ required: true, message: t('forms:requiredMessage.name') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label={t('forms:label.email')}
          rules={[
            { required: true, message: t('forms:requiredMessage.email') }
          ]}
        >
          <Input type="email" disabled={authedUser} />
        </Form.Item>
        <Form.Item
          name="role_name"
          label={t('forms:label.role')}
          rules={[{ required: true, message: t('forms:requiredMessage.role') }]}
        >
          <Select disabled={authedUser && authedUser.role_name === 'company'}>
            <Select.Option value="company">
              {t('settings.company')}
            </Select.Option>
            <Select.Option value="admin">{t('settings.admin')}</Select.Option>
          </Select>
        </Form.Item>
        <Button htmlType="submit" style={{ float: 'right' }}>
          {t('common:submit')}
        </Button>
      </Form>
      {authedUser && (
        <>
          <Divider>{t('settings.changePassword')}</Divider>
          <Form
            form={changePasswordForm}
            onFinish={changePassword}
            layout="vertical"
          >
            <Form.Item
              name="old_password"
              label={t('forms:label.oldPassword')}
              rules={[
                {
                  required: true,
                  message: t('forms:requiredMessage.oldPassword')
                }
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="new_password"
              label={t('forms:label.newPassword')}
              rules={[
                {
                  required: true,
                  message: t('forms:requiredMessage.newPassword')
                }
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label={t('forms:label.confirmPassword')}
              rules={[
                {
                  required: true,
                  message: t('forms:requiredMessage.confirmPassword')
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      t('forms:requiredMessage.notSameAsNewPassword')
                    );
                  }
                })
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Button htmlType="submit" style={{ float: 'right' }}>
              {t('forms:label.changePassword')}
            </Button>
          </Form>
        </>
      )}
    </Card>
  );
}

export default Settings;
