import { createSlice } from '@reduxjs/toolkit';
import configuration from 'config';
import * as verifyToken from 'utils/verifyToken';
import { apiCallBegan } from './types/api';

const storedUser = 'userData';

const user = createSlice({
  name: 'user',
  initialState: {
    data: {},
    loading: true
  },
  reducers: {
    userRequested: (user) => {
      user.loading = true;
    },
    userLoaded: (user, action) => {
      user.data = { ...action.payload.data };
      user.loading = false;
    },
    userLoggedIn: (user, action) => {
      const userData = verifyToken.verifyToken(action.payload.data);
      if (!userData || !userData.access_token) {
        user.data = {};
        user.loading = false;

        return;
      }

      const roles = configuration().allowedRoles || '';
      const allowedRoles = roles.split(',');
      if (!allowedRoles.includes(userData.role_name)) {
        user.data = {};
        user.loading = false;
        return;
      }

      user.data = { ...userData };
      user.data = action.payload.data;
      verifyToken.storeUser(action.payload.data);
      user.loading = false;
    },
    userRemoved: (user) => {
      user.data = {};
      verifyToken.removeUser();
      user.loading = false;
    },
    userRequestFailed: (user) => {
      user.loading = false;
    }
  }
});

export const {
  userRequested,
  userLoaded,
  userLoggedIn,
  userRemoved,
  userRequestFailed
} = user.actions;
export default user.reducer;

export const login = (data) => apiCallBegan({
  url: '/login',
  method: 'POST',
  data,
  headers: null,
  onStart: userRequested.type,
  onSucces: userLoggedIn.type,
  onError: userRequestFailed.type
});

export const logout = () => apiCallBegan({
  url: '/logout',
  method: 'GET',
  headers: verifyToken.verifyLogout(storedUser),
  onStart: userRequested.type,
  onSucces: userRemoved.type,
  onError: userRemoved.type
});

export const loadUser = () => {
  const userData = verifyToken.isLogin(storedUser);

  if (userData) {
    return userLoaded({ data: { ...userData } });
  }

  return userRequestFailed();
};
