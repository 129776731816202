import { useDispatch } from 'react-redux';

import { Form, Input, Button, Divider } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

// Store
import { login } from 'store/user';

import logoPath from 'assets/images/verkauf_deinehandy_logo.png';

// Style
import './Auth.css';

function Auth() {
  // Hooks
  const dispatch = useDispatch();
  const { t } = useTranslation('pages');

  // Handlers
  const authSubmitHandler = (values) => {
    dispatch(login(values));
  };

  return (
    <div className="fullBG">
      <div className="authentication">
        <div className="logo-slogan">
          <img src={logoPath} alt="logo" />
        </div>
        <Divider style={{ background: '#fff' }} />
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={authSubmitHandler}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('forms:requiredMessage.email') }
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder={t('forms:placeHolder.email')}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: t('forms:requiredMessage.password') }
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t('forms:placeHolder.password')}
            />
          </Form.Item>
          <Divider style={{ background: '#fff' }} />

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {t('auth.login')}
            </Button>

          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Auth;
