import { formatFilters } from 'utils/helper.util';
import BaseService from './base.service';

export class Submission extends BaseService {
  async searchSubmission(id, type = 'SU') {
    if (type === 'SU') {
      return this.getSubmission(id);
    }

    return this.getOtherSubmission(id);
  }

  async getSubmission(id) {
    const params = {
      include: 'device;device.brand;partner;client'
    };

    const res = await this.axios.get(`/submissions/${id}`, { params });

    return res.data;
  }

  async getSubmissions(params) {
    const { formatedFilters, updatedParams } = formatFilters(params);
    const _params = new URLSearchParams(updatedParams);

    const res = await this.axios.get(
      `/submissions?${_params}${formatedFilters}`
    );

    return res.data;
  }

  async deleteSubmission(id) {
    const res = await this.axios.delete(`/submissions/${id}`);
    return res.data;
  }

  async getOtherSubmission(id) {
    const res = await this.axios.get(`/other-submissions/${id}`);
    return res.data;
  }

  async getOtherSubmissions(params) {
    const { formatedFilters, updatedParams } = formatFilters(params);
    const _params = new URLSearchParams(updatedParams);

    const res = await this.axios.get(
      `/other-submissions?${_params}${formatedFilters}`
    );
    return res.data;
  }

  async deleteOtherSubmission(id) {
    const res = await this.axios.delete(`/other-submissions/${id}`);
    return res.data;
  }

  async updateStatusSubmission({ id, isOtherSubmission, selectedStatus }) {
    const url = isOtherSubmission
      ? `/other-submission/change-status/${id}`
      : `/submission/change-status/${id}`;

    const res = await this.axios.put(url, { status_name: selectedStatus });
    return res.data;
  }

  async getStatuses() {
    const res = await this.axios.get('/statuses');
    return res.data;
  }

  async getDashboard() {
    const res = await this.axios.get('/dashboard');
    return res.data;
  }
}

const submissionService = new Submission();
export default submissionService;
